.quote-details-recap {
  display: grid;
  grid-template-columns: 1fr auto;
  padding: 4rem 1rem;
  border-top: 1px solid $brandPrimary;
  border-bottom: 1px solid $brandPrimary;

  .quote-details-recap-comment {
    &__label {
      font-weight: $semiBold;
      margin-bottom: 1rem;
    }
  }

  .quote-details-recap-table {
    .quote-details-recap-table-row {
      &__label {
        font-weight: $bold;
        padding-right: 2rem;
      }

      &__value {
        text-align: right;
      }
    }
  }
}
