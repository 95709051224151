.quote-table {
  .table-cell.quote-table__actions {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;

    .quote-status-actions {
      margin-right: 2rem;
    }
  }

  .quote-status {
    white-space: nowrap;
    &--pending {
      color: $yellow;
    }
    &--accepted {
      color: $green;
    }
    &--declined {
      color: $red;
    }
  }
}
