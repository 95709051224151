@use "sass:math";

.auth-container {
  margin: 0 auto;
  border: 1px solid $shade03;
  width: 100%;
  padding: $boxSizeMargin * 2 $boxSizeMargin * 2 $boxSizeMargin * 3;
  background: $pristineWhite;
  line-height: 1.4;

  &__header {
    color: $brandPrimary;
    border: none;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &__title {
      font-size: $veryLargeSize;
      font-weight: $semiBold;
    }
  }

  &__form {
    & * input {
      text-align: left;
      padding-left: 2rem;
      padding-right: 2rem;
    }

    & > .alert {
      margin-top: 2rem;
    }
  }

  &__content {
    font-weight: $regular;
    text-align: left;
  }

  &__link {
    color: $black;
    display: block;
  }
}

.form-actions__element {
  overflow: hidden;
  margin: 0;
}

@media (min-width: $laptop) {
  .auth-container {
    width: 90%;
    max-width: 65rem;
  }
}
