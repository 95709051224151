@use "sass:math";

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  list-style: none;
  padding: 1.5rem;
  margin: 0;
  background-color: $pristineWhite;
  font-size: 1.4rem;
  line-height: 1.7rem;

  &__item {
    display: flex;
    align-items: center;
    color: $brandPrimary;
    text-transform: lowercase;
    font-size: $smallSize;
    letter-spacing: 0;

    .link {
      text-decoration: underline;
    }

    &::after {
      display: block;
      content: ">";
      padding: 2px math.div($boxSizeMargin, 4) 0;
    }

    &:last-child {
      .link {
        text-decoration: none;
      }
      &::after {
        display: none;
      }
    }
  }

  &__link-name {
    text-decoration: underline;
  }

  &.last-link {
    text-decoration: none;
  }
}

@media screen and (min-width: $laptop), print {
  .breadcrumb {
    padding: 0 3.3rem;
    height: 5.9rem;

    &__item {
      &::after {
        padding: 2px math.div($boxSizeMargin, 2) 0;
      }
    }
  }
}

@media print {
  .breadcrumb {
    font-size: 12pt;
  }
}
