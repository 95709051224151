.section {
  &__title {
    flex: 1;
    text-align: center;

    > * {
      margin-top: 0;
      margin-bottom: 0;
      display: flex;
      font-weight: bold;
    }
  }
  .section__head {
    display: flex;
    @media screen and (min-width: $laptop) {
      margin-bottom: $boxSizeMargin * 2;
    }
  }
  &--with-actions {
    .section__head {
      align-items: center;
    }
    .section__title {
      flex: 1;
      text-align: left;
      margin-right: $boxSizeMargin;
    }
  }

  @media screen and (min-width: $menuBreakpoint) {
    .section {
      &__head {
        margin-bottom: $boxSizeMargin;
      }

      &__content {
        margin: 0 1rem;
      }
    }

    &--with-actions {
      .section__title {
        margin-right: $boxSizeMargin * 2;
      }
    }
  }
}
