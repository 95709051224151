.select-input {
  position: relative;
  display: inline-block;
  width: 100%;
  border: 1px solid $shade03;
  border-radius: 4px;
  background-color: $backgroundColor;
  padding: 0;

  &:focus-within {
    border-color: $shade05;
  }

  > :last-child {
    position: absolute;
    right: 1.6rem;
    top: 50%;
    transform: translate(0, -50%);
    pointer-events: none;
    color: $shade03;
  }

  > select {
    background-color: #fff;
    border: none;
    padding: 0.1rem 4rem 0 2.5rem;
    font-size: $regularSize;
    color: $brandPrimary;
    position: relative;
    -webkit-appearance: none;
    appearance: none;
    &::-ms-expand {
      display: none;
    }
  }

  &--invalid {
    border-color: $warning;
    > :last-child {
      color: $warning;
    }
  }

  &--valid {
    border-color: $shade05;
    > :last-child {
      color: $shade05;
    }
  }
}
