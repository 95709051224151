@use "sass:math";

.number-input {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0;
  border-radius: 0.4rem;
  border: 1px solid $mainGray;
  background-color: white;
  min-width: 9rem;

  &__button {
    margin: 0 math.div($boxSizeMargin, 2);
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
    > * {
      display: block;
    }
  }

  &__input {
    z-index: 1;
    > * {
      border: none;
      background: none;
      border-radius: 0;
      padding: 0;
      text-align: center;
      width: 4ch;
      font-weight: bold;
      font-variant-numeric: tabular-nums;
    }
  }

  &--small {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin: 0;
    width: 100%;
    min-width: auto;
    .number-input__button {
      &:first-child {
        margin-top: math.div($boxSizeMargin, 4);
      }
      &:last-child {
        margin-bottom: math.div($boxSizeMargin, 4);
      }
    }
    .number-input__input {
      > * {
        height: auto;
      }
    }
  }
}
