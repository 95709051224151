.grouped-product-view {
  &__container {
    display: flex;
    flex-direction: column;
    height: auto;
  }

  &__gallery {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #fff;

    .product-gallery {
      border-bottom: 0;
    }
  }

  &__synthesis {
    background-color: $white;

    .synthesis-head {
      display: none;
    }
  }

  .horizontal-product-item {
    border-left: none;

    &:first-of-type {
      border-top: none;
    }
  }

  .product-description {
    line-height: 1.4;
  }
}

@media screen and (min-width: $laptop) {
  .grouped-product-view {
    &__container {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
    }

    &__gallery {
      flex: 1;
      justify-content: center;
      align-items: center;
      width: 35rem;
    }

    &__synthesis {
      flex: 1;
      width: 100%;
      height: auto;
    }
  }
}

@media screen and (min-width: $laptop) and (max-width: ($desktop - 1px)) {
  .grouped-product-view__gallery {
    width: 100%;
  }
  .grouped-product-view {
    .product-description {
      padding: 3rem 1.5rem;
      line-height: 1.4;
    }
  }
}

@media screen and (min-width: $desktop) {
  .grouped-product-view {
    &__container {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: row;
      border-bottom: 1px solid $shade07;

      & > * {
        align-self: stretch;
      }

      .synthesis-head {
        display: none;
      }
    }

    &__gallery {
      flex: 0 25%;
      max-width: 42.8rem;
      border-top: 1px solid $shade07;
    }

    &__synthesis {
      padding: 3rem 3.2rem;
      border-top: 1px solid $shade07;
      border-left: 1px solid $shade07;

      .synthesis-head {
        display: block;
      }

      .product-description {
        margin-top: 3rem;
      }
    }

    &__products {
      display: flex;

      &:before {
        content: "";
        width: 25%;
        flex: none;
        max-width: 42.8rem;
        border-right: 1px solid $shade07;
      }

      &-wrapper {
        flex-direction: column;
        flex: auto;
      }
    }
  }
}

@media print {
  .grouped-product-view {
    .accordion {
      &__content {
        opacity: 1;
        max-height: 200rem;
        padding: 1rem 1.5rem;
      }
    }
  }
}
