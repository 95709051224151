.requisition-list-table {
  &__name {
    .link {
      font-weight: $bold;

      &:hover,
      &:focus {
        text-decoration: none;
        color: $brandSecondary;
      }
    }
  }

  .table-cell.requisition-list-table__actions {
    display: flex;
    gap: 0.5rem;
  }
}
