@use "sass:math";

.paginator {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;

  &__page {
    display: block;
    font-size: $mediumSize;

    &:focus {
      outline: none;
    }
    &:focus-visible {
      outline: none;
    }

    &--previous,
    &--next {
      flex: 1;
      font-size: $regularSize;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 0.2em;
    }
    &--next {
      .paginator__link {
        text-align: right;
      }
    }

    &--disabled {
      .paginator__link {
        color: $shade03;
        cursor: not-allowed;

        &:hover,
        &:focus {
          text-decoration: none;
        }
      }
    }

    &--active {
      .paginator__link {
        font-weight: $bold;
        text-decoration: underline;
      }
    }
  }

  &__link {
    display: block;
    text-decoration: none;
    padding: math.div($boxSizeMargin, 2) $boxSizeMargin;

    &:hover,
    &:focus {
      font-weight: bold;
      cursor: pointer;
      outline: none;
    }
    &:focus-visible {
      outline: auto;
    }
  }
}
