.checkbox-switch {
  color: $mainGray;
  font-size: $regularSize;
  display: flex;
  align-items: center;

  &__wrapper {
    position: relative;
    display: inline-flex;
    background-color: $mainGray;
    width: 5.5rem;
    height: 3.2rem;
    border-radius: 1.6rem;
  }

  &:hover {
    cursor: pointer;
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  &__button {
    position: absolute;
    width: 100%;
    height: 100%;
    &:after {
      content: "";
      position: absolute;
      left: 2px;
      top: 2px;
      height: 2.8rem;
      width: 2.8rem;
      border-radius: 2.8rem;
      background-color: $pristineWhite;
      transition: left 0.2s ease-in-out;
    }
  }

  input:checked {
    & + .checkbox-switch__button:after {
      left: calc(100% - 3rem);
      transition: left 0.2s ease-in-out;
    }
  }
}
