.product-result-item {
  .autocomplete-item__name {
    .link {
      display: flex;
      flex-direction: column;

      &:hover,
      &:focus {
        text-decoration: none;

        .product-result-item__name {
          text-decoration: underline;
        }
      }
    }
  }

  &__name {
    margin-bottom: 0.5rem;
  }
  &__sku {
    display: flex;
    align-items: center;
    gap: 0.3rem;
    font-size: 1.4rem;
    color: $secondaryGray;
    font-weight: $regular;
  }
}
