.account-navigation {
  padding-left: 0;
  margin: 0;

  &--mobile {
    display: block;
  }

  &--desktop {
    display: none;
  }

  .link {
    text-decoration: none;
  }

  &__item {
    position: relative;
    color: $fontColor;
    padding: 0 0 3rem 0;
    font-style: italic;
    font-size: 2rem;

    &:disabled,
    &--disabled {
      pointer-events: none;
      color: $shade03;
    }

    &:hover {
      color: $brandSecondary;
    }

    &--active,
    &:focus {
      font-weight: $bold;
      color: $brandSecondary;
    }

    &:hover,
    &:focus,
    &--active {
      .icon {
        color: initial;
      }
    }

    .labelled-icon {
      text-align: start;
    }
  }
}

@media screen and (min-width: $tablet) {
  .account-navigation {
    &__item {
      .labelled-icon {
        .body {
          white-space: nowrap;
        }
      }
    }
  }
}

@media screen and (min-width: $desktop) {
  .account-navigation {
    &--mobile {
      display: none;
    }

    &--desktop {
      display: block;
    }
  }
}
