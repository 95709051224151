.product-tag {
  display: inline-flex;
  align-items: center;
  font-size: 1.4rem;
  padding: 1rem 1.5rem;
  border-radius: 2.5rem;
  color: $pristineWhite;
  text-transform: uppercase;

  &--new {
    background-color: $brandSecondary;
  }

  &--discounted {
    background-color: $color03;

    .icon {
      width: 1.6rem;
      height: 1.6rem;
    }
  }

  .icon {
    margin-right: 0.7rem;
    width: 1.8rem;
    height: 1.8rem;
  }
}
