$navigationMenuWidth: 24.8rem;

* {
  box-sizing: border-box;
}

.wrapper {
  position: relative;
}
.refreshing {
  cursor: wait;
  opacity: 0.2;
}
@mixin container {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: $boxSizeMargin * 1.5;
  padding-right: $boxSizeMargin * 1.5;
}
.container {
  @include container;
}
.container--mobile-only {
  @include container;
}
@media screen and (min-width: $tabletBreakpoint) {
  .container--tablet-only {
    @include container;
  }
}
@media screen and (min-width: $menuBreakpoint) {
  .container--mobile-only {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  .container--tablet-only {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  .container--desktop-only {
    @include container;
    max-width: $containerWidth + $boxSizeMargin * 3;
  }
}
.wrapper > .container {
  margin: $boxSizeMargin auto;
}
.main-content {
  position: relative;
  outline: none;
  z-index: 1;
  display: flex;
  justify-content: center;
}

.page-content {
  &--with-image-top-mobile {
    padding-top: 0;
  }
  &--simple {
    padding-top: $boxSizeMargin * 2;
    padding-bottom: $boxSizeMargin * 4;
  }
  &--error {
    color: $red;
  }
  @media screen and (min-width: $menuBreakpoint) {
    &--simple {
      padding-top: $boxSizeMargin * 6;
      padding-bottom: $boxSizeMargin * 8;
    }
  }
}

.without-outer-margin {
  > :first-child {
    margin-top: 0;
  }
  > :last-child {
    margin-bottom: 0;
  }
}

@media print {
  .container {
    max-width: unset;
    padding: 0;
  }

  .page-content {
    padding: 0;
  }
}

input[type="text"]::-webkit-input-placeholder {
  color: $mainGray;
  opacity: 0.7;
}

.mdl-layout {
  &__content {
    width: 100%;
    .search-box {
      display: flex;
    }
  }
}

@media screen and (min-width: $laptop) {
  .mdl-layout {
    &__content:not(&__content--guest) {
      width: calc(100% - $navigationMenuWidth);
    }
  }
}

@media screen and (min-width: $desktop) {
  .mdl-layout {
    &__content {
      .search-box {
        display: none;
      }
    }
  }
}

@import "~./Header/Header";
@import "~./Footer/Footer";
@import "./CustomNavigationMenu/CustomNavigationMenu";
