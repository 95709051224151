$color01: #002e5b;
$color02: #0f658a;
$color03: #97062e;
$color04: #e4e4e4;
$color05: #25b5c9;
$color06: #209cad;

$brandPrimary: $color01;
$brandSecondary: $color02;
$pristineWhite: #fff;
$black: #000;
$mainGray: #cccccc;
$secondaryGray: #aeaeae;
$skyBlue: #209cad;
$discountColor: $color03;
$discountColorGradient-1: $color03;
$discountColorGradient-2: #b20635;
$categoriesColorGradient-1: #0f658a;
$categoriesColorGradient-2: #1c8ea4;
$mainRed: #b30635;

$primaryGradientStart: $skyBlue;
$primaryGradientEnd: $brandSecondary;
$secondaryGradientStart: $brandSecondary;
$secondaryGradientEnd: $brandPrimary;
$defaultGradientStart: $secondaryGray;
$defaultGradientEnd: #5a5a5a;
$itemTransitionDuration: 400ms;

$orange: #ff5501;
$warning: #fd7737;

$red: #e02020;
$error: $red;

$green: #24bf24;

$yellow: #e79a03;

$shade01: #f4f7f6;
$shade02: #f0f0f0;
$shade03: #dadada;
$shade04: #9b9b9b;
$shade05: #333333;
$shade06: $black;
$shade07: #d5d9d7;
$shade08: #707070;
$shade09: #dbdedd;

$white: $shade01;

$success: $green;
$info: #e79a03;
$error: $red;

$invertedWarning: #ff9a9a;

$divider: $shade03;
$blockBackground: $white;
$callToAction: $brandPrimary;

$fontDisabled: $shade05;
$fontSecondaryColor: $shade05;
$titleColor: $brandPrimary;

$backgroundColor: $white;
$fontColor: #131433;
$reverseBackgroundColor: $fontColor;
$reverseFontColor: $backgroundColor;

$buttonPrimaryColor: $brandPrimary;
$buttonSecondaryColor: $white;

$callToActionColor: $buttonSecondaryColor;
$callToActionBackground: $buttonPrimaryColor;

$hoverableColor: $brandPrimary;

$iconColor: $brandPrimary;
$notificationColor: $white;
$notificationBackground: $brandPrimary;

$modalColor: $black;
$modalBackground: $backgroundColor;
$modalBorderColor: $shade04;

$productBackground: $backgroundColor;
$productColor: $black;

$productPriceColor: $fontSecondaryColor;
$promotionBorderColor: $brandSecondary;

$optionBackground: $shade03;
$optionColor: $black;
$optionItemBackground: $white;
$optionItemBorderColor: $shade04;
$optionItemColor: $fontDisabled;
$optionItemActiveBorderColor: $shade04;

$inputBorderColor: $mainGray;

/* export variables used in `Colors.story.js` */
:export {
  brandprimary: $brandPrimary;
  brandsecondary: $brandSecondary;

  shade01: $shade01;
  shade02: $shade02;
  shade03: $shade03;
  shade04: $shade04;
  shade05: $shade05;
  shade06: $shade06;

  uisuccess: $success;
  uiinfo: $info;
  uiwarning: $warning;
  uierror: $error;

  text-black: $black;
  text-white: $white;
}
