.layer-pagination {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem;
  background-color: $shade01;
}

@media screen and (min-width: $tablet) {
  .layer-pagination {
    flex-direction: row;
  }
}
