@font-face {
  font-family: "Gotham";
  font-weight: 400;
  font-style: normal;
  src: local("Gotham Book"), local("Gotham-Book"),
    url(./Gotham-Font/Gotham-Book.eot?#iefix) format("embedded-opentype"),
    url(./Gotham-Font/Gotham-Book.woff2) format("woff2"),
    url(./Gotham-Font/Gotham-Book.woff) format("woff"),
    url(./Gotham-Font/Gotham-Book.ttf) format("truetype"),
    url(./Gotham-Font/Gotham-Book.svg#Gotham-Book) format("svg");
}

@font-face {
  font-family: "Gotham";
  font-weight: 500;
  font-style: normal;
  src: local("Gotham Medium"), local("Gotham-Medium"),
    url(./Gotham-Font/Gotham-Medium.eot?#iefix) format("embedded-opentype"),
    url(./Gotham-Font/Gotham-Medium.woff2) format("woff2"),
    url(./Gotham-Font/Gotham-Medium.woff) format("woff"),
    url(./Gotham-Font/Gotham-Medium.ttf) format("truetype"),
    url(./Gotham-Font/Gotham-Medium.svg#Gotham-Medium) format("svg");
}

@font-face {
  font-family: "Gotham";
  font-weight: 700;
  font-style: normal;
  src: local("Gotham Bold"), local("Gotham-Bold"),
    url(./Gotham-Font/Gotham-Bold.eot?#iefix) format("embedded-opentype"),
    url(./Gotham-Font/Gotham-Bold.woff2) format("woff2"),
    url(./Gotham-Font/Gotham-Bold.woff) format("woff"),
    url(./Gotham-Font/Gotham-Bold.ttf) format("truetype"),
    url(./Gotham-Font/Gotham-Bold.svg#Gotham-Bold) format("svg");
}

@font-face {
  font-family: "Gotham";
  font-weight: 400;
  font-style: italic;
  src: local("Gotham Book Italic"), local("Gotham-Book-Italic"),
    url(./Gotham-Font/Gotham-BookItalic.eot?#iefix) format("embedded-opentype"),
    url(./Gotham-Font/Gotham-BookItalic.woff2) format("woff2"),
    url(./Gotham-Font/Gotham-BookItalic.woff) format("woff"),
    url(./Gotham-Font/Gotham-BookItalic.ttf) format("truetype"),
    url(./Gotham-Font/Gotham-BookItalic.svg#Gotham-BookItalic) format("svg");
}

@font-face {
  font-family: "Gotham";
  font-weight: 500;
  font-style: italic;
  src: local("Gotham Medium Italic"), local("Gotham-Medium-Italic"),
    url(./Gotham-Font/Gotham-MediumItalic.eot?#iefix)
      format("embedded-opentype"),
    url(./Gotham-Font/Gotham-MediumItalic.woff2) format("woff2"),
    url(./Gotham-Font/Gotham-MediumItalic.woff) format("woff"),
    url(./Gotham-Font/Gotham-MediumItalic.ttf) format("truetype"),
    url(./Gotham-Font/Gotham-MediumItalic.svg#Gotham-MediumItalic) format("svg");
}

@font-face {
  font-family: "Gotham";
  font-weight: 700;
  font-style: italic;
  src: local("Gotham Bold Italic"), local("Gotham-Bold-Italic"),
    url(./Gotham-Font/Gotham-BoldItalic.eot?#iefix) format("embedded-opentype"),
    url(./Gotham-Font/Gotham-BoldItalic.woff2) format("woff2"),
    url(./Gotham-Font/Gotham-BoldItalic.woff) format("woff"),
    url(./Gotham-Font/Gotham-BoldItalic.ttf) format("truetype"),
    url(./Gotham-Font/Gotham-BoldItalic.svg#Gotham-BoldItalic) format("svg");
}
