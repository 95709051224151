.accounting-line-table {
  .link {
    font-weight: $bold;

    &:focus,
    &:hover {
      color: $brandSecondary;
      text-decoration: none;
    }
  }

  .accounting-line-table-total-row {
    &__due-date,
    &__total {
      font-weight: $bold;
    }

    &__due-date {
      text-align: right;
    }
  }
}
