.grid-cards {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-auto-rows: 1fr;
  grid-gap: 1.4rem 1rem;
  padding: 2rem;

  > a {
    width: inherit;
    &:nth-of-type(odd) {
      justify-self: end;
    }
    &:nth-of-type(even) {
      justify-self: start;
    }
  }

  > .link {
    height: 100%;
    text-decoration: none;
  }
}

@media screen and (min-width: 768px) {
  .grid-cards {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 1.6rem 1.8rem;
    & > a {
      width: 100%;
    }
  }
}

@media screen and (min-width: 1040px), print {
  .grid-cards {
    grid-template-columns: repeat(3, minmax(0, 1fr));

    > a {
      justify-self: center;

      &:nth-of-type(odd) {
        justify-self: center;
      }
      &:nth-of-type(even) {
        justify-self: center;
      }
    }
  }
}

@media screen and (min-width: 1830px) {
  .grid-cards {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-gap: 2.1rem 2.4rem;

    > a {
      &:nth-of-type(odd) {
        justify-self: end;
      }
      &:nth-of-type(even) {
        justify-self: start;
      }
    }
  }
}

@media screen and (min-width: 1920px) {
  .grid-cards {
    max-width: 1920px;

    > a {
      &:nth-of-type(odd) {
        justify-self: center;
      }
      &:nth-of-type(even) {
        justify-self: center;
      }
    }
  }
}

@media print {
  .grid-cards {
    grid-template-columns: repeat(3, minmax(0, 1fr));

    .link {
      margin: 0 0.25cm;
    }
  }
}
