.requisition-list-update-modal {
  .modal__title {
    margin-bottom: 2rem;
  }

  .form-actions {
    margin: 2rem 0 0;
    gap: 1rem;
  }
}
