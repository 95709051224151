.account-layout {
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;

  .modal-title {
    font-size: $largeSize;
    font-weight: bold;
  }

  &__nav {
    width: 100%;
    margin: 2rem 0 3rem 0;
  }

  &__main {
    width: 100%;
  }
}

.account-page-title {
  font-size: 2.4rem;
  font-weight: $bold;
  margin-bottom: 4rem;
}

@media screen and (min-width: $desktop), print {
  .account-layout {
    display: flex;
    flex-direction: row;

    &__nav {
      padding-right: 1rem;
      width: 26.1rem;
      margin: 0;
    }

    &__main {
      padding-left: 1rem;
      margin-bottom: 3rem;
      width: calc(100% - 26.1rem);
    }

    &__success-message {
      margin-bottom: $boxSizeMargin;
    }
  }
}

@media print {
  .account-layout {
    display: block;

    &__nav {
      display: none;
    }

    &__main {
      width: 100%;
    }
  }

  .account-page-title {
    font-size: 18pt;
    margin: 0.8cm 0;
  }
}

@import "~./AccountTitle";
