.table-search-row {
  .table-search-cell {
    display: table-cell;
    padding: 0.5rem;

    input {
      padding: 0 1rem;
      height: 3.5rem;
    }
  }
}
