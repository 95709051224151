.requisition-list-files {
  padding-left: 1rem;
  margin-bottom: 4rem;

  .form-item {
    &__label {
      font-weight: $bold;
      margin-bottom: 0.5rem;
    }
  }
}
