.login-form {
  &__terms-of-use {
    text-align: justify;
    text-justify: distribute;
  }
  &__terms-of-use-checkbox {
    .input-wrapper {
      .checkbox label {
        line-height: 2rem;
        padding-top: 0;
      }
    }
  }

  & .input-wrapper {
    padding-top: 1rem;
  }
}

@media (min-width: $tablet) {
  .login-form {
    .input-wrapper {
      padding-top: 0;
    }
  }
}
