.price {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;

  &--hidden {
    visibility: hidden;
  }

  > div {
    width: 8ch;
  }
}
