.one-line-form {
  display: flex;
  flex-direction: column;
  align-items: center;

  &--button-icon {
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-end;

    .one-line-form__input {
      flex: 1;
      min-width: 15ch;
      margin-bottom: 0;
    }
  }

  @media screen and (min-width: 30em) {
    align-items: flex-start;
    flex-direction: row;
  }
}
