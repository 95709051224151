.exploded-views {
  &__title > .h1 {
    font-weight: $semiBold;
    font-size: 1.9rem;
    margin-top: 3rem;
    margin-bottom: 0;
  }
}

@media screen and (min-width: $laptop) {
  .exploded-views {
    &__title > .h1 {
      font-size: 3rem;
      margin-top: 5rem;
    }
  }
}

@media screen and (max-width: $tablet - 1) {
  .exploded-views {
    &__title > .h1 {
      margin-bottom: 2rem;
    }
  }
}
