.horizontal-product-item {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
  background-color: white;
  border: 1px solid $shade07;
  border-bottom: none;
  position: relative;

  &:last-of-type {
    border-bottom: 1px solid $shade07;
  }

  &__top-container {
    flex: 1;
    display: flex;
    align-items: center;
  }

  &__bottom-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  & > .link {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
  }

  &__image {
    margin-right: 2.3rem;
    min-width: 9.5rem;
    min-height: 9.5rem;
  }

  &__overview {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__name {
    font-weight: $bold;
  }

  &__prices {
    display: flex;
    flex-direction: column;
    font-weight: $semiBold;
    font-size: 1.6rem;

    &--hidden-prices {
      opacity: 0;
      visibility: hidden;
    }
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
}

.custom-modal__content {
  .horizontal-product-item {
    gap: 1rem;
    padding: 1rem;

    &__image {
      margin-right: 0.8rem;
      min-width: 8rem;
      min-height: 8rem;
    }

    &__prices {
      font-size: 1.4rem;
    }
  }
}

@media screen and (min-width: 600px), print {
  .horizontal-product-item {
    flex-direction: row;
  }

  .custom-modal__content {
    .horizontal-product-item {
      gap: 2rem;
      padding: 2rem;

      &__image {
        margin-right: 2.3rem;
        min-width: 9.5rem;
        min-height: 9.5rem;
      }

      &__prices {
        font-size: 1.6rem;
      }
    }
  }
}

@media screen and (min-width: $tablet), print {
  .horizontal-product-item {
    display: grid;
    grid-template-columns: 0.8fr 1fr;
    &__bottom-container {
      margin-left: auto;
      flex-direction: row;
      align-items: center;
    }
  }
}

@media screen and (min-width: $laptop) {
  .horizontal-product-item {
    &__bottom-container {
      gap: 3rem;
    }
  }
}

@media screen and (min-width: $desktop) {
  .horizontal-product-item {
    &__bottom-container {
      gap: 4rem;
    }
  }
}

@media print {
  .horizontal-product-item {
    &__actions {
      display: none;
    }
  }
}
