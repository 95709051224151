.product-gallery {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1rem;
  border: 1px solid $shade07;

  &__slider {
    height: 100%;
  }

  &__zoom {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 7rem);
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    text-align: center;
    cursor: pointer;
    background: transparent;
    border: 0;
    text-transform: uppercase;

    &--loading {
      background: rgba(0, 0, 0, 0.2);
    }
  }

  &__slider-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    bottom: 0;
    padding: 1.5rem 1rem;

    button {
      color: $shade03;
      transition: all 0.3s ease-in-out;

      &:hover {
        color: $shade04;
      }
    }

    .button--icon-disabled {
      padding: 0;
      background-color: transparent;
      cursor: not-allowed;
      opacity: 0.4;
    }
  }

  &__main {
    flex: 5;
    align-self: flex-start;
    position: relative;
    max-width: 30rem;
  }

  &__nav {
    position: absolute;
    bottom: $boxSizeMargin * 1;
    left: $boxSizeMargin * 2;
    right: $boxSizeMargin * 2;
    display: flex;
  }
  &__button {
    position: relative;
    display: block;
    flex: 1;
    height: $boxSizeMargin * 2;
    background: none;
    color: transparent;
    font-size: 0;
    border: none;
    cursor: pointer;

    &::after {
      position: absolute;
      content: "";
      height: 3px;
      width: 100%;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      background: white;
    }

    &:hover,
    &:focus {
      outline: none;
      &::after {
        height: 6px;
        background: $black;
      }
    }
    &--current {
      &::after {
        background: $black;
      }
    }
  }

  &__aside {
    display: none;
    flex: 1;
  }

  &__preview-button {
    position: relative;
    background: none;
    padding: 0;
    border: none;
    cursor: pointer;
    outline: none;

    &:hover,
    &:focus {
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border: 1px solid $shade03;
      }
    }
  }
}

@media screen and (min-width: $laptop) and (max-width: $desktop - 1) {
  .product-gallery {
    border-left: 0 none;
    border-right: 0 none;
  }
}

@media screen and (min-width: $desktop) {
  .product-gallery {
    border: 0 none;
    &__aside {
      display: block;
      max-width: 15%;
      min-width: 7.2rem;
      padding-right: $boxSizeMargin * 2;
    }

    &__nav {
      display: none;
    }

    &__slider-buttons {
      padding: 2rem 1rem;
    }

    &__main {
      flex: 1;
      max-width: 100%;
    }
  }
}

@media print {
  .product-gallery {
    &__zoom {
      display: none;
    }

    &__main {
      max-width: 22rem;
    }
  }
}
