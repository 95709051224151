.delivery-method {
  display: flex;
  align-items: center;
  text-align: start;

  .icon {
    margin-right: 1rem;
  }

  &__print {
    display: none;
    border: 1pt solid $brandSecondary;
    width: 0.45cm;
    height: 0.45cm;
    margin-right: 0.25cm;
    color: $white;
    background-color: $white;
    text-align: center;
  }
  &__print--checked {
    background-color: $brandPrimary;
  }
}

@media print {
  .delivery-method {
    .icon {
      display: none;
    }

    &__print {
      display: block;
    }
  }
}
