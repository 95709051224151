@use "sass:math";

.account-orders-details {
  &__item {
    border-bottom: 1px solid $divider;
    padding: $boxSizeMargin 0;

    &__title {
      font-weight: bold;
    }
  }

  &__ordered-items {
    > *:first-child {
      padding-top: 0;
    }
    > *:last-child {
      border-bottom: none;
    }

    &:last-child > :last-child {
      padding-bottom: 0;
    }
  }

  &__bottom-container {
    display: flex;
    justify-content: space-between;
    padding: 4rem 1rem;
    border-top: 1px solid $brandPrimary;
    border-bottom: 1px solid $brandPrimary;
  }

  &__comment {
    strong {
      display: block;
      margin-bottom: 1rem;
    }
  }

  .recap-card__children {
    padding-bottom: 0;
  }
}

@import "~./OrderDetailsLayout/OrderActions";
