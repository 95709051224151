.wavesoft-order-table-filters {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  gap: 2rem;

  .filter-sku {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-bottom: 2rem;

    &__label {
      font-size: 2rem;
      font-weight: $bold;
    }

    &__selected-product {
      display: flex;
      gap: 1rem;
      align-items: center;
      justify-content: space-between;
      border-radius: 3px;
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      background-image: linear-gradient(
        $secondaryGradientStart,
        $secondaryGradientStart,
        $secondaryGradientEnd
      );
      color: $white;

      .selected-product {
        &__label {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
        }

        &__name {
          font-size: 1.6rem;
          font-weight: $bold;
        }
      }
    }
  }
}

@media screen and (min-width: $tablet), print {
  .wavesoft-order-table-filters {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    .date-range-picker {
      padding-bottom: 2rem;
    }
  }
}
