.file-uploader {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 64rem;

  &__form {
    max-width: 100%;
    text-align: center;
    position: relative;
  }

  &__input {
    display: none;
  }

  &__drop-zone {
    cursor: pointer;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4rem;
    border-width: 2px;
    border-radius: 1rem;
    border-style: dashed;
    border-color: #cbd5e1;
    background-color: #f8fafc;
    transition: border 200ms ease-out;

    &--active,
    &:hover:not(&--disabled) {
      background-color: #ffffff;
      border-color: $brandPrimary;
    }

    &--disabled {
      border-color: #cbd5e1;
      background-color: #f8fafc;
    }

    b {
      font-weight: $semiBold;
    }
  }

  &__error-message {
    color: $error;
  }

  &__files {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .file-uploader-file-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      padding: 1rem;
      border: 1px solid $brandPrimary;
      border-radius: 1rem;
      background-color: $pristineWhite;

      &__action {
        .spinner {
          height: 2.4rem;
          margin-bottom: 0;

          &::before {
            width: 2.4rem;
            height: 2.4rem;
            top: 0;
            left: 0;
            margin-top: 0;
            margin-left: -2.4rem;
          }
        }
      }
    }
  }

  .drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
}
