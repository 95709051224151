.icon-with-notification {
  position: relative;
  display: block;

  > :first-child {
    display: block;
  }

  &__notification {
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: $smallSize;
    position: absolute;
    bottom: -25%;
    right: -25%;
    color: $white;
    background: $info;
    line-height: 1em;
    max-height: 2.125em;
    width: 2.125em;
    padding: 0.32em 0.3em 0.28em;
    border-radius: 50%;
    text-align: center;
  }
}

@media (min-width: $laptop) {
  .icon-with-notification {
    &__notification {
      bottom: 10%;
      right: -30%;
    }
  }
}
