@use "sass:math";

.layer-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  color: $shade08;
  background-color: $shade01;
  padding: 1rem;

  &__total {
    flex: 1;
    font-weight: $bold;
    color: $shade08;
  }
}

@media screen and (min-width: $desktop) {
  .layer-header {
    padding: 1rem 3.3rem;
    flex-direction: row;
  }
}
