.wishlist-page {
  &__title {
    display: flex;
    align-items: center;
    padding: 0 1.4rem;
    height: 6rem;
    border-bottom: 1px solid $shade07;
    background-color: $pristineWhite;

    h1 {
      font-size: 2.8rem;
      line-height: 2.5rem;
      font-weight: $semiBold;
    }
  }
}
@media screen and (min-width: $laptop) {
  .wishlist-page {
    &__title {
      padding: 0 3.3rem;

      h1 {
        font-size: 3rem;
      }
    }
  }
}

@media screen and (min-width: $desktop) {
  .wishlist-page {
    &__title {
      .h1 {
        margin-top: 0.5rem;
        margin-bottom: 1.2rem;
      }
    }

    &__total {
      padding: 1rem 3.3rem;
    }
  }
}
