.totals {
  display: flex;
  justify-content: flex-end;

  &__table {
    .total-row {
      &__label {
        font-weight: $bold;
        padding-right: 2rem;
      }

      &__value {
        text-align: right;
      }
    }
  }
}
