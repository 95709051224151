.footer {
  display: flex;
  flex-direction: column;
  color: $pristineWhite;
  background-color: $brandPrimary;

  &__top {
    flex-direction: column;

    .info {
      .icon {
        width: 3.6rem;
        height: 4.1rem;
      }
    }

    .contact {
      img {
        width: 4.3rem;
        height: 3.2rem;
      }
    }

    .social {
      display: flex;
      align-items: center;
      justify-content: center;

      &__title {
        display: none;
      }

      .social-media {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;

        &__item {
          line-height: 1;
          background-color: $pristineWhite;
          border-radius: 50%;
          padding: 0.4rem;

          svg {
            fill: $brandPrimary;
          }
        }
      }
    }

    .info,
    .contact {
      flex-basis: 25%;
      align-items: center;
      justify-content: center;

      .link {
        display: flex;
        align-items: center;
        justify-content: center;

        div {
          margin-right: 1.4rem;
        }
      }
    }

    .info,
    .contact,
    .social {
      display: flex;
      padding: 1.5rem;
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.6rem;
    height: 15.3rem;

    .link {
      color: $skyBlue;
      text-decoration: underline;
    }

    .hyphen {
      display: none;
    }

    .legal-notices,
    .privacy-policy {
      margin-top: 1.8rem;
    }
  }
}

@media screen and (min-width: $desktop) {
  .footer {
    &__top {
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid $shade07;

      .social {
        flex-basis: 50%;
        justify-content: flex-end;
        gap: 3rem;
        padding-right: 5rem;

        &__title {
          display: block;
        }
      }

      .info,
      .contact,
      .social {
        height: 8rem;
      }

      .info,
      .contact {
        border-right: 1px solid $color04;
        border-bottom: none;
      }
    }

    &__middle {
      display: block;
    }

    &__bottom {
      flex-direction: row;
      justify-content: center;
      height: 8rem;
      border-top: 1px solid $shade07;

      .link {
        color: $skyBlue;
        text-decoration: underline;
      }

      .hyphen {
        display: block;
      }

      .legal-notices,
      .privacy-policy {
        margin-top: 0;
      }
    }
  }
}

@media print {
  .footer {
    padding: 1cm 0;

    &__top,
    .legal-notices,
    .privacy-policy,
    .hyphen {
      display: none;
    }

    &__bottom {
      height: auto;
    }
  }
}

@import "~./BottomBar";
