.home {
  .h2 {
    margin: 2.5rem 0 0.8rem 1rem;
    font-weight: $semiBold;
  }

  &__categories-grid {
    display: flex;
    justify-content: center;
  }
}

@media screen and (min-width: $tablet), print {
  .home {
    .h2 {
      font-size: 3rem;
    }
    .items-slider + .h2 {
      margin-top: 7rem;
      margin-bottom: 2rem;
    }
  }
}
