.bottombar {
  &__logo {
    display: flex;
    justify-content: center;

    img {
      width: 250px;
      height: 80px;
    }
  }
  &__categories,
  &__links {
    display: none;
  }
}

@media screen and (min-width: $desktop) {
  .bottombar {
    display: flex;
    padding: 7.6rem 0;

    .link-list {
      margin: 0;

      &__title {
        font-size: 1.9rem;
        line-height: 2.5rem;
        white-space: pre-line;
        height: 6.9rem;
      }

      &__item {
        font-size: 1.6rem;
        line-height: 2.8rem;
        font-weight: $light;
      }
    }

    &__logo {
      flex-basis: 25%;
      display: flex;
      justify-content: center;
    }

    &__categories {
      display: flex;
      flex-basis: 50%;
    }

    &__links {
      flex-basis: 25%;
      display: flex;
      flex-direction: column;

      .link-list {
        &__list {
          display: flex;
          flex-direction: column;
        }

        &__item {
          width: auto;
        }
      }

      > .link {
        &:first-of-type {
          margin: 2rem 0 1.5rem;
        }

        font-size: 1.9rem;
        line-height: 2.5rem;
        font-weight: $bold;
        text-transform: uppercase;
      }
    }
  }
}

@media print {
  .bottombar {
    margin-bottom: 0.5cm;
  }
}

@import "~./Brand";
