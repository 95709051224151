.navigation {
  height: 7.5rem;
  &__list {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    list-style: none;
    margin: 0;
    padding-inline-start: 0;

    .navigation-item {
      height: 100%;
      display: flex;
      align-items: center;

      &__label {
        display: none;
      }

      .icon-basket,
      .icon-user,
      .icon-cart {
        img {
          height: 3.4rem;
        }
      }

      .icon-basket {
        img {
          width: 2.8rem;
        }
      }
      .icon-user {
        img {
          width: 3.6rem;
        }
      }
      .icon-cart {
        img {
          width: 3.7rem;
        }
      }

      a[href="/wishlist"] {
        margin-right: 1rem;
      }
    }
  }
}

@media screen and (min-width: $tablet) {
  .navigation {
    &__list {
      .navigation-item {
        &__label {
          display: flex;
          flex-direction: column;
          margin-left: 1.2rem;

          a {
            line-height: 1.9rem;
          }

          a:nth-child(2) {
            color: $color05;
            font-size: 1.4rem;
            line-height: 1.7rem;
          }
        }
      }
    }
  }
}

@media screen and (min-width: $desktop) {
  .navigation {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    &__list {
      width: 100%;
      max-width: 75rem;

      .navigation-item--separator {
        height: 100%;
        width: 1px;
        background-color: $color04;
      }
    }
  }
}

@import "~./Menu/MenuNavigationMobile";
@import "~./Menu/MenuMainCategories";
@import "~./Menu/CategoriesMenu";
@import "~./AccountNavigation/AccountSubNavigation";
