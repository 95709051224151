.autocomplete-item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $shade07;

  &__image {
    min-width: 9.2rem;
    min-height: 9.2rem;
    margin-right: 2.6rem;
    border-right: 1px solid $shade07;
  }

  &__name {
    color: $brandPrimary;
    white-space: break-spaces;
    font-weight: $bold;
  }

  &__addon {
    font-weight: normal;
  }
}
