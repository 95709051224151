@use "sass:math";

.add-to-cart {
  &__error {
    outline: none;
    width: 100%;
    > * {
      margin: 0;
    }
  }

  &__main {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    margin-bottom: 2.5rem;
  }
  &--center &__main {
    justify-content: center;
  }

  &__quantity {
    flex: 1;
    padding: 0 0.5rem 0 0;

    .number-input {
      border-radius: 4px;
      background-color: #fff;
      height: 5.4rem;
    }
  }

  &__button {
    flex: 3;
    max-width: 100%;
    .button {
      height: 5.4rem;
      .labelled-icon__content > :first-child {
        margin-right: 0.7rem;
      }
    }
    > * {
      width: 100%;
    }
  }

  &__actions {
    display: none;

    @media screen and (min-width: $menuBreakpoint) {
      display: block;
    }
  }
}
@media screen and (min-width: $desktop) {
  .add-to-cart {
    &__button {
      .button {
        .labelled-icon__content > :first-child {
          margin-right: 1.9rem;
        }
      }
    }
  }
}

@media screen and (min-width: $desktop) {
  .add-to-cart {
    &__main {
      display: block;
      margin-bottom: 0;
    }

    &__quantity {
      padding: 0.8rem 0;
      margin-bottom: 3rem;
    }
  }
}
