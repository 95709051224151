$boxSizeMargin: 1.6rem;
$smallContainerWidth: 64rem;
$containerWidth: 160rem;

@import "~./normalize";

/* height and body min-height are needed so that "aside" submenus (cart, user
 * menu) are correctly displayed when the page is shorter than the aside submenu.
 * see https://linear.app/front-commerce/issue/FC-499
 */
html {
  height: 100%;
}

body {
  min-height: 100%;
}

.ReactModal__Overlay--after-open {
  z-index: 40;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.downloadable-file {
  cursor: pointer;
  text-decoration: underline;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

@import "~theme/components/components";
@import "~theme/layouts/Layout";
@import "~theme/modules/modules";
@import "~theme/pages/pages";
@import "~theme/print";
