.maintenance {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: $laptop) {
  .maintenance {
    &__image-container {
      max-width: 50%;
    }
  }
}
