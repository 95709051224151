.product-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem;
  text-align: left;
  margin-bottom: 2rem;

  thead {
    border-bottom: 2px solid $shade05;
    tr {
      height: 4rem;
    }
  }

  th {
    min-width: 8rem;
  }

  .product-table-row {
    &__name-and-comment-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      text-align: left;

      .product-comment {
        &__label {
          font-weight: $bold;
          margin-bottom: 0.5rem;
        }
      }
    }
  }
}
