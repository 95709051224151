.account-tracking-information {
  .encours {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-bottom: 4rem;
    margin-bottom: 4rem;
    border-bottom: 1px solid $shade03;

    .encours-item {
      display: flex;
      font-size: 2rem;

      &__label {
        font-weight: $semiBold;
      }
    }
  }
}

@media screen and (min-width: $desktop) {
  .account-tracking-information {
    .encours {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
    }
  }
}
