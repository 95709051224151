@mixin border-card($color) {
  transition: background 400ms ease-out;

  &:hover {
    outline: 3px solid $color;
    border: 1px solid $color;
  }
}

@mixin base-card-style {
  display: flex;
  border-radius: 0.4rem;
  border: 1px solid $shade07;
  box-shadow: 0px 25px 30px #00000029;
  width: 17.6rem;
  height: 15.2rem;
  position: relative;
  text-decoration: none;

  &__image {
    position: relative;

    .background-image {
      z-index: 1;

      &:after {
        background-color: transparent;
      }
    }
  }

  &:hover {
    text-decoration: none;
  }

  a .link {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  @media screen and (min-width: 768px) {
    width: 36rem;
    height: 22.3rem;
  }

  @media screen and (min-width: 1040px) {
    width: 100%;
  }

  @media screen and (min-width: 1830px) {
    width: 39rem;
  }

  @media screen and (min-width: 1920px) {
    width: 100%;
  }
}

.new-product-card {
  @include base-card-style;
  @include border-card($color02);

  flex-direction: column;
  justify-content: space-between;
  padding: 1.2rem 0 0 1.5rem;
  color: $pristineWhite;
  background-color: $categoriesColorGradient-2;
  background: transparent
    linear-gradient(
      180deg,
      $categoriesColorGradient-1 0%,
      $categoriesColorGradient-2 100%
    )
    0% 0% no-repeat padding-box;

  &__title {
    font-weight: $semiBold;
    width: 100%;
    font-size: 1.9rem;
    line-height: 2.2rem;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__link {
    visibility: hidden;
    text-decoration: underline;
  }

  &__image {
    right: 1.2rem;
    bottom: 0.9rem;

    img {
      width: 5rem;
      height: 5rem;
    }
  }
}

.category-card {
  @include base-card-style;
  @include border-card($color06);

  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  padding: 0;
  background-color: #fff;

  &__image {
    flex: 1.2;
    padding-right: 0;
    width: 100%;
    height: 100%;

    .responsive-image {
      &__actual {
        object-fit: contain;
      }
    }
  }

  &__title {
    display: flex;
    align-items: center;
    flex: 0.8;
    color: $color01;
    line-height: 2rem;
    padding: 0.5rem 1rem 0.5rem 3rem;
    text-align: left;
    width: 100%;
  }
}

@media screen and (min-width: $tablet) {
  .new-product-card {
    padding: 2.7rem 0 3.6rem 3.2rem;
    background-image: url("/assets/etiquette-bleu.svg"),
      linear-gradient(
        90deg,
        $categoriesColorGradient-1,
        $categoriesColorGradient-2,
        $categoriesColorGradient-1
      );
    background-size: 14.1rem, 200% 100%;
    background-position: right 4rem center, 0 0;
    background-repeat: no-repeat;
    &:hover {
      background-position: right 4rem center, 100% 0;
    }

    &__title {
      width: 85%;
      font-size: 2.8rem;
      line-height: 3.5rem;
      padding: initial;
    }

    &__link {
      visibility: visible;
    }

    &__image {
      right: 3.2rem;
      bottom: 1.6rem;

      img {
        width: 6rem;
        height: 6rem;
      }
    }
  }

  .category-card {
    flex-direction: row;
    align-items: center;

    &__title {
      flex: 1;
      font-size: 1.9rem;
      line-height: 2.5rem;
      padding-right: 1.5rem;
      padding-left: 1.5rem;
    }

    &__image {
      flex: 1;
      padding-bottom: 0;
      padding-right: 1.5rem;
      width: 100%;
      height: 100%;

      .responsive-image {
        width: 100%;
        height: 100%;

        &__actual {
          object-fit: cover;
        }
      }
    }
  }
}

@media print {
  .new-product-card {
    width: 5.5cm;
  }

  .category-card {
    margin: 0 0.25cm;
    width: 5.5cm;

    &__title {
      font-size: 14pt;
    }
  }
}
