.add-product-to-list-modal {
  &__header {
    border-bottom: 1px solid $shade07;
  }

  &__title {
    text-align: left;
    b {
      /* Using text-shadow instead of bold because bold make text go up and break alignment */
      text-shadow: 1px 0px 0px $brandPrimary;
      font-weight: normal;
    }
  }

  &__content {
    padding: 0;
  }

  .product-row {
    display: flex;
    flex-direction: column;

    &__main-container {
      display: flex;
      flex-direction: column;
    }

    &__checkbox-and-image-container {
      width: 100%;
      align-self: center;
      display: flex;
      align-items: center;
      border-bottom: 1px solid $shade07;
    }

    &__fake-checkbox {
      padding-left: 3rem;

      input {
        margin-right: 0;
      }

      label {
        padding-left: 3rem;
        min-height: 3rem;
      }

      .checkbox input[type="checkbox"]:checked + label:before {
        font-size: 2rem;
        line-height: 3rem;
        width: 3rem;
        height: 3rem;
      }
    }

    &__image {
      display: flex;
      justify-content: center;
      padding: 2rem 0;
      width: 100%;
    }

    &__middle-container {
      padding: 3.3rem 1.2rem 0;
    }

    &__name {
      font-size: 1.9rem;
      line-height: 2.5rem;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 1.4rem;
    }

    &__sku {
      margin-bottom: 3rem;
    }

    &__sku,
    &__configuration {
      line-height: 1.9rem;
    }

    &__configuration {
      margin-bottom: 2rem;
    }

    &__bottom-container {
      display: flex;
      align-items: center;
      padding: 0 1.2rem 1.9rem;
      border-bottom: 1px solid $shade07;
    }

    &__quantity {
      margin-right: 1.5rem;
    }

    &__prices {
      font-weight: $semiBold;

      &--hidden-prices {
        visibility: hidden;
      }
    }

    &__comment-container {
      padding: 1.5rem 2rem;
      border-bottom: 1px solid $shade07;

      .form-item {
        &__label {
          font-weight: $bold;
          margin-bottom: 1.5rem;
        }

        &__input {
          .input-wrapper {
            margin-top: 0;
          }
        }
      }
    }
  }

  .list-selector-handler,
  .create-new-list-handler {
    &__label {
      font-weight: $bold;
      margin-bottom: 1.5rem;
    }

    &__input-and-button {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }
  }

  .list-selector-handler {
    padding: 3.2rem 1.2rem 0;
    margin-bottom: 2rem;

    select {
      option:disabled {
        color: $secondaryGray;
      }
    }
  }

  .create-new-list-handler {
    padding: 0 1.2rem 3.2rem;

    .button {
      height: 5.5rem;
      width: 50%;
    }
  }

  .related-products-block {
    &__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 3rem 1.8rem;
      background-color: $shade01;
    }

    &__title {
      font-size: 1.9rem;
      font-weight: $semiBold;
      margin-bottom: 2rem;
    }

    &__back {
      cursor: pointer;

      &:hover,
      &:focus {
        .labelled-icon {
          .body {
            text-decoration: underline;
          }
        }
      }

      .labelled-icon {
        svg {
          color: $mainGray;
        }
      }
    }

    .horizontal-product-item {
      flex-direction: row;
      align-items: center;
    }

    .horizontal-product-item:last-of-type {
      border-bottom: none;
    }
  }
}

.add-product-to-list {
  &__quantity {
    margin-bottom: 1.5rem;
  }

  &__button {
    display: flex;
    align-items: center;

    .add-to-cart-button {
      flex: 1;
    }
  }

  .naked-error-message {
    margin-top: 1rem;
  }
}

@media screen and (min-width: $tablet) {
  .add-product-to-list-modal {
    .product-row {
      &__main-container {
        flex-direction: row;
        align-items: flex-end;
        border-bottom: 1px solid $shade07;
        padding-right: 3.5rem;
      }

      &__fake-checkbox {
        align-self: center;
        padding-left: 1.5rem;
      }

      &__checkbox-and-image-container {
        width: fit-content;
        border-bottom: 0;
      }

      &__image {
        align-self: center;
        padding: 0 1.5rem 0 2rem;
        border-bottom: 0;
      }

      &__sku {
        margin-bottom: 0;
        height: 5.6rem;
      }

      &__middle-container {
        padding: 1.5rem 2rem;
        border-left: 1px solid $shade07;
      }

      &__prices {
        white-space: nowrap;
      }

      &__configuration {
        margin-bottom: 0;
      }

      &__bottom-container {
        border-bottom: 0;
        padding: 0 0 2rem;
      }
    }

    .list-selector-handler,
    .create-new-list-handler {
      &__label {
        margin-bottom: 1.5rem;
      }

      &__input-and-button {
        display: grid;
        grid-template-columns: 2fr 1fr;
      }
    }

    .list-selector-handler {
      padding: 2.5rem 3.5rem 0;
    }

    .create-new-list-handler {
      padding: 0 3.5rem 2.5rem;
    }

    .related-products-block {
      &__header {
        flex-direction: row;
        justify-content: space-between;
        padding: 2.8rem 3.5rem;
      }

      &__title {
        margin-bottom: 0;
      }
    }
  }
}

@media print {
  .product-synthesis__col-right {
    .add-product-to-list {
      &__button {
        display: none;
      }
    }
  }
}
