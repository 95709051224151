.paginator-with-page-size {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem;
  background-color: $shade01;
}

@media screen and (min-width: $tablet) {
  .paginator-with-page-size {
    flex-direction: row;
  }
}
