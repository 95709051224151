.product-search {
  position: relative;

  &__results {
    z-index: 100;
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 24rem;
    overflow-y: auto;
    background-color: $pristineWhite;
    border: 1px solid $brandPrimary;

    .product-search-result {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      padding: 1rem;

      &:not(:last-of-type) {
        border-bottom: 1px solid $shade07;
      }

      &:hover,
      &:focus {
        background-color: $shade01;
      }

      &__name {
        font-weight: $bold;
      }

      &__sku {
        font-size: 1.4rem;
      }
    }
  }

  &__loading,
  &__no-products-found {
    padding: 2rem;
  }
}
