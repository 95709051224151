.table-container__header {
  .table-header-cell {
    cursor: default;
    user-select: none;
    font-weight: $bold;
    padding: 1rem 0.5rem;

    &__container {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__label {
      margin-right: 1rem;
      white-space: nowrap;
    }

    &__sort {
      display: none;
    }

    &--sortable {
      cursor: pointer;

      .table-header-cell {
        &__sort {
          display: block;
          opacity: 0.3;
          &--active {
            opacity: 1;
          }
        }
      }

      &:hover,
      &:focus {
        .table-header-cell {
          &__sort {
            opacity: 1;
          }
        }
      }
    }
  }
}
