.requisition-list-update {
  margin-right: 2.5rem;

  .button--link {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: $bold;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}
