.table-cell {
  text-align: center;
  padding: 2.6rem 0.5rem;

  .price {
    justify-content: center;
  }
}

@media print {
  .table-cell {
    padding: 0.5cm 0.2cm;
  }
}
