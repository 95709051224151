.date-range-picker {
  form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  &__label {
    font-size: 2rem;
    font-weight: $bold;
    margin-bottom: 2rem;
  }

  &__inputs {
    display: flex;
    gap: 1rem;

    .date-range-picker-input {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;
    }
  }

  &__actions {
    display: flex;
    align-items: flex-end;
    gap: 0.5rem;

    .button:first-of-type {
      text-transform: uppercase;
    }

    .button {
      padding: 1rem;
    }
  }
}

@media print {
  .date-range-picker {
    &__label {
      font-size: 14pt;
      margin-bottom: 0.3cm;
    }

    &__inputs {
      .date-picker {
        padding: 0.15cm;
        height: 1.2cm;
        font-size: 11pt;
      }
    }

    &__actions {
      display: none;
    }
  }
}
