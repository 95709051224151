.accordion {
  border: 1px solid $shade03;
  background-color: #fff;
  width: 100%;

  & + .accordion {
    border-top: 0 none;
  }

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.3rem 1.2rem;
    color: $brandPrimary;
    font-size: $regularSize;
    cursor: pointer;

    .icon-wrapper {
      color: $shade04;
      transform: rotate(0deg);
      transition: all 200ms;
    }
  }

  &__content {
    padding: 0 1rem;
    opacity: 0;
    max-height: 0;
    color: $fontColor;
    font-size: $smallSize;
    overflow-y: auto;
    transition: opacity 200ms ease-in-out, max-height 200ms 200ms ease-in-out,
      padding 200ms 200ms ease-in-out;
  }

  &--open {
    .accordion__content {
      opacity: 1;
      max-height: 200rem;
      padding: 1rem 1.5rem;
      transition: opacity 200ms 200ms ease-in-out, max-height 200ms ease-in-out,
        padding 200ms ease-in-out;
    }

    .accordion__head {
      .icon-wrapper {
        transform: rotate(180deg);
      }
    }
  }
}

@media print {
  .accordions {
    .accordion:first-child {
      .accordion__content {
        opacity: 1;
        max-height: 200rem;
        padding: 1rem 1.5rem;
      }
    }
  }
}
