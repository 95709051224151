.table-container {
  overflow: auto;
  &__table {
    display: table;
    text-indent: initial;
    border-spacing: 2px;

    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1rem;
  }

  &__header {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
    border-bottom: 1px solid $brandPrimary;
    padding: 1rem 0.5rem;
  }

  &__body {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }
}

@media print {
  .table-container {
    overflow: none;

    &__table {
      display: block;
      overflow: hidden;
    }
  }
}
