.after-sales-service-details-modal {
  &__title {
    font-weight: $semiBold;
  }

  .after-sales-service-details {
    &-general {
      margin-bottom: 3rem;
    }

    &-general,
    &-intervention {
      &__title {
        font-size: 2.2rem;
        font-weight: $semiBold;
        margin-bottom: 2rem;
      }

      &__content {
        margin-left: 3rem;
        .after-sales-service-detail {
          display: flex;
          margin-bottom: 1rem;
          font-size: 1.8rem;

          &__label {
            font-weight: $semiBold;
            width: 25rem;
            margin-right: 2rem;
          }
        }
      }
    }
  }
}
