.wavesoft-order-status {
  &--progress {
    color: $success;
  }
  &--preparing {
    color: $yellow;
  }
  &--shipped {
    color: $color03;
  }
  &--billed {
    color: $brandSecondary;
  }
}
