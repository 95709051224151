.information {
  display: flex;
  flex-direction: column;

  &__container {
    display: grid;
    grid-template-columns: initial;
    grid-template-rows: 1fr 1fr;
    gap: 3rem;
    margin: 2rem;
  }

  .user-information,
  .change-user-password-form {
    padding: 0 1rem;

    &__fields {
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }
  }

  .user-information {
    &__fields {
      input {
        &:disabled {
          color: initial;
        }
      }
    }
  }

  .form-actions {
    margin-top: 2rem;
  }
}

@media screen and (min-width: $horizontalTablet) {
  .information {
    &__container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: initial;
    }
  }
}
