.account-title {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;

  > * {
    margin: 0;
  }
}

@media screen and (min-width: $desktop) {
  .account-title {
    margin: 1rem 0 3rem 0;
  }
}
