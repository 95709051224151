.horizontal-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 2rem 2.2rem 2rem 1.3rem;
  border: 1px solid $shade07;

  &--bg-white {
    background-color: $pristineWhite;
  }

  & + .horizontal-card {
    border-top: 0;
  }

  &__top-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &__image {
    width: 5rem;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      width: 3.5rem;
      height: 3.5rem;
    }
  }

  &__description {
    font-weight: $semiBold;
  }

  &__button {
    text-align: center;
  }
}

@media screen and (min-width: $tablet) {
  .horizontal-card {
    padding: 2rem 2.2rem 2rem 1.3rem;

    &__button {
      & > .button {
        width: 100%;
      }
    }

    &__image {
      width: 9.5rem;
      height: 9.5rem;
    }
  }
}
