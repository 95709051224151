@use "sass:math";

.header {
  background-color: $brandPrimary;
  color: $white;
  position: sticky;
  top: 0;
  z-index: 10;
  border-bottom: 1px solid $shade07;

  &__container {
    display: flex;
    height: 7.5rem;
  }

  &__menu {
    min-width: 5.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid $white;

    &--open {
      background-color: $pristineWhite;
      color: $brandPrimary;
    }

    .icon-cross {
      svg {
        width: 1.8rem;
        height: 1.8rem;
      }
    }

    .icon-menu {
      padding: 0 1.4rem;

      img {
        width: 2.7rem;
        height: 1.4rem;
      }
    }
  }

  &__logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid $shade07;
    padding: 0 0.8rem;

    img {
      width: 13.3rem;
      height: 4.3rem;
    }
  }

  &__right-container,
  &__links {
    width: 100%;
  }

  &__search-bar {
    display: none;
  }

  &__print-logo {
    display: none;
  }
}

@media screen and (min-width: $laptop) {
  .header {
    &__menu {
      display: none;
    }
    &__logo-container {
      min-width: 24.8rem;
    }
  }
}

@media screen and (min-width: $desktop) {
  .header {
    &__right-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    &__search-bar {
      display: block;
    }
  }
}

@media print {
  .header {
    display: none;
    border: 0;

    &__container {
      min-height: unset;

      > * {
        display: none;
      }
    }
    &__logo {
      display: block;
    }

    &__print-logo {
      display: flex;
      justify-content: center;
      background-color: $brandPrimary;
      padding: 0.5cm 0;
    }
  }
}

@import "~./Navigation/Navigation";
