@import "~./Fonts/fonts";

$fontFamily: "Gotham", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto;

$superLight: 100;
$light: 200;
$regular: 400;
$semiBold: 500;
$bold: 700;

$tinySize: 1.1rem;
$extraSmallSize: 1.2rem;
$smallSize: 1.4rem;
$regularSize: 1.6rem;
$mediumSize: 1.7rem;
$largeSize: 1.9rem;
$veryLargeSize: 2.2rem;
$extraLargeSize: 3.2rem;
$giantSize: 4rem;

$mediumIconSize: 2.5rem;
$smallIconSize: 1.9rem;

/* export variables used in `Typography.story.js` */
:export {
  family-roboto: $fontFamily;
  weight-regular: $regular;
  weight-semibold: $semiBold;
  weight-bold: $bold;
  size-tinysize: $tinySize;
  size-extrasmallsize: $extraSmallSize;
  size-smallsize: $smallSize;
  size-regularsize: $regularSize;
  size-mediumsize: $mediumSize;
  size-extralargesize: $extraLargeSize;
  size-giantsize: $giantSize;
  size-mediumiconsize: $mediumIconSize;
  size-smalliconsize: $smallIconSize;
}

html {
  font-size: 0.6255em; /* fallback IE8+ */
  font-size: calc(
    1em * 0.6255
  ); /* IE9-IE11 math fixing. See http://bit.ly/1g4X0bX */
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-size-adjust: 100%;
}

body {
  background: $backgroundColor;
  color: $brandPrimary;
  font-family: $fontFamily;
  font-style: normal;
  font-weight: $regular;
  line-height: 1;
  font-size: 1.6rem;
}

@media print {
  html {
    font-size: 10pt;
  }
}

@import "~theme/components/atoms/Typography/Body/Body";
@import "~theme/components/atoms/Typography/Heading/Heading";
@import "~theme/components/atoms/Typography/Link/Link";
@import "~theme/components/atoms/Typography/Link/Anchor";
@import "~theme/components/atoms/Typography/Price/Price";
