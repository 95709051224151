.quote-details-modal {
  &__title {
    font-weight: $semiBold;
    text-transform: uppercase;
    text-align: left;
  }

  &__content {
    padding: 0 3.5rem 4.5rem 3.5rem;
  }

  &__actions {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;

    .quote-status-actions {
      margin-right: 2rem;
    }
  }
}
