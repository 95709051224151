.address {
  line-height: 2.7rem;

  &__title {
    font-size: $mediumSize;
    font-weight: $bold;
  }

  .body {
    white-space: break-spaces;
  }
}
