.confirmation-modal {
  .form-title {
    margin-bottom: 1rem;
  }

  .form-actions {
    margin: 1rem;
    gap: 1rem;
  }
}
