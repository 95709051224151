.exploded-view {
  .product-view__gallery {
    flex: none;
    width: 100%;
    max-width: 100%;
    .product-gallery {
      max-width: 50rem;
    }
  }

  &__product-filter {
    padding: $boxSizeMargin;
    max-width: 100%;
  }

  &__product-list {
    padding: 0 1.6rem 1.6rem;
  }

  &__product-list-no-results {
    padding: $boxSizeMargin * 2 $boxSizeMargin;
    background-color: $pristineWhite;
    border: $border-gray;
    text-align: center;
  }

  &__product__back-to-product,
  .synthesis-head {
    padding: 1.2rem;
  }

  &__product__synthesis {
    display: flex;

    &__download {
      display: flex;
      padding: 1.2rem;
      justify-content: space-between;
      gap: 1rem;

      .button {
        height: fit-content;
        width: max-content;
      }
    }
  }

  .exploded-view-row {
    display: flex;
    flex-direction: column;
    border: 1px solid $shade07;
    border-bottom: none;

    &:last-of-type {
      border-bottom: 1px solid $shade07;
    }

    &__marker {
      flex: none;
      display: flex;
      justify-content: center;
      padding: 2rem;
      flex-direction: column;
      text-align: center;
      background-color: $pristineWhite;

      &-label {
        display: inline-block;
        width: 6rem;
        padding: 1rem;
        border: $border-gray;
        align-self: center;
        font-weight: $bold;
      }
    }

    .horizontal-product-item {
      flex: 1;
      border: none;

      &__bottom-container {
        flex-direction: column;
      }
    }
  }

  &__product-list-item + &__product-list-item {
    .exploded-view-row {
      border-top: 0;
    }
  }

  &__product-download {
    text-align: center;
    padding: $boxSizeMargin;
  }

  &__product-download-technical-docs {
    .h2 {
      font-weight: $semiBold;
      padding-left: 1.2rem;
    }
  }
}

@media screen and (min-width: 850px) {
  .exploded-view {
    .exploded-view-row {
      flex-direction: row;

      &__marker {
        border-right: 1px solid $shade07;
      }

      .horizontal-product-item {
        &__bottom-container {
          flex-direction: row;
        }
      }
    }

    &__product__synthesis {
      display: flex;
      flex-direction: row;
    }
  }
}

@media print {
  .exploded-view-row {
    .horizontal-product-item {
      gap: 1rem;
      padding: 1rem;
      grid-template-columns: 1.5fr 1fr;

      &__bottom-container {
        flex-direction: row;
      }
    }

    &__marker {
      padding: 0.5rem;
    }
  }
}
