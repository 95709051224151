$border-gray: solid 1px $shade03;

.product {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 1.6rem;

  &__details {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 2.4rem 0;
  }

  &__gallery {
    width: 100%;
    align-self: flex-start;
    margin: 0 auto;

    &__main {
      position: relative;
      width: 100%;
      margin-bottom: 1.6rem;
    }
  }

  &__synthesis {
    margin: auto;
    max-width: 400px;
    background: $white;
    border-radius: 5px;
    box-shadow: 0 2px 6px 0 rgba($shade05, 0.35);
    padding: 1.6rem;
  }

  &__add-to-cart {
    margin: 2em 0;
  }

  &__out-of-stock {
    font-size: $mediumSize;
    color: $shade05;
  }

  &__breadcrumb {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $pristineWhite;
    padding-right: 1.5rem;
  }
}

.product-view {
  &__container {
    display: flex;
    flex-direction: column;
    height: auto;
  }

  &__gallery {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #fff;
  }

  &__description {
    margin: 2rem;
  }
}

.product-synthesis {
  &__container {
    flex-direction: column;
    min-height: auto;
  }

  &__col-left {
    flex: 3;
    border-right: $border-gray;
    padding: 1.2rem;
    margin-bottom: 2rem;
  }

  &__col-right {
    border-top: 1px solid $shade07;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 2;
    padding: 1.2rem;
  }

  &__head {
    h1 {
      text-transform: uppercase;
      line-height: 1.4;
      margin: 0 0 0.5rem 0;
    }

    .stack {
      margin-top: 0.5rem;
    }

    .synthesis-head {
      display: none;
    }
  }

  &__ref {
    font-size: $regularSize;
    margin-bottom: 0.5rem;
    color: $brandPrimary;
  }

  &-prices {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 1rem 0 2rem;
    font-weight: $regular;
    line-height: 1.4;

    .price {
      font-size: $largeSize;
    }
  }
  .product-description {
    line-height: 1.4;
  }

  .icon-not-saleable {
    .icon {
      width: 3.4rem;
      height: 3.4rem;
    }
  }
}

.synthesis-head {
  padding: 1.5rem;
  width: 100%;

  .product-tag {
    margin-bottom: 1.5rem;
  }

  h1 {
    margin-bottom: 0.5rem;
    margin-top: 0;
    text-transform: uppercase;
    font-weight: $semiBold;
    font-size: 2.8rem;
    line-height: 1.12;
  }

  .product-synthesis__ref {
    margin-bottom: 1rem;
  }
}

.external {
  &-tabs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid $shade03;
    padding: 1rem;
    font-size: $mediumSize;

    span {
      font-size: $mediumSize;
    }
  }

  &-tab {
    padding: 1.5rem;
    cursor: pointer;

    &:last-child {
      text-transform: lowercase;
      text-decoration: underline;
    }

    .icon-download-light > .icon {
      width: 3.5rem;
      height: 3.5rem;
    }
  }
}

@media screen and (min-width: $laptop) {
  .product {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;

    &__gallery,
    &__synthesis {
      margin: 2em;
    }
    &__gallery {
      flex: 1.2;
      min-width: 250px;
    }
    &__synthesis {
      min-width: 250px;
      width: 33%;
      flex: 1;
      .configurator {
        max-width: 100%;
        form > .stack {
          flex-direction: row;
        }
      }
    }
    &__breadcrumb {
      padding-right: 3.3rem;
    }
  }

  .product-view {
    &__container {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
    }

    &__gallery {
      flex: 1;
      justify-content: center;
      align-items: center;
      width: 35rem;
    }

    &__synthesis {
      flex: 1;
      width: 100%;
      height: auto;
    }
  }

  .product-synthesis {
    &__col-right,
    &__col-left {
      padding: 1.5rem;
    }
    &-prices {
      padding: 2rem 0;
      line-height: 1.8;
      border-bottom: 0 none;
    }
  }

  .synthesis-head {
    .product-tag {
      margin-bottom: 2rem;
    }
  }
}

@media screen and (min-width: $laptop) and (max-width: ($desktop - 1px)) {
  .product-view__gallery {
    width: 100%;
  }
}

@media screen and (min-width: $desktop) {
  .product-view {
    &__container {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: row;
      & > * {
        align-self: stretch;
      }

      .synthesis-head {
        display: none;
      }
    }

    &__gallery {
      flex: 0 25%;
      max-width: 42.8rem;
      border-top: 1px solid $shade07;
    }
  }

  .product-synthesis {
    height: 100%;

    &__container {
      display: flex;
      flex-direction: row;
      border: $border-gray;
      border-top: none;
      background-color: $shade02;
      height: 100%;
    }

    &__col-left {
      border-top: 1px solid $shade07;
      padding: 3rem 3.3rem;
      margin-bottom: 0;
    }

    &__col-right {
      padding: 3rem 2.8rem;
    }

    &__head {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      .synthesis-head {
        display: block;
      }
    }
  }

  .synthesis-head {
    padding: 0;

    .product-synthesis__ref {
      display: flex;
      align-items: center;
      font-size: $largeSize;
      margin-bottom: 1.5rem;
    }

    & > .h1 {
      line-height: 1.2;
      font-size: 3rem;
      margin-bottom: 1rem;
    }
  }

  .external {
    &-tab {
      padding: 0.25rem;
    }
  }
}

@media print {
  .synthesis-head {
    & > .h1 {
      font-size: 18pt;
    }

    .product-synthesis__ref {
      font-size: 16pt;
    }
  }

  .product-synthesis {
    &__col-left {
      display: none;
    }

    &-prices,
    .price {
      font-size: 15pt;
    }
  }

  .external {
    &-tab {
      display: none;
    }
  }
}
