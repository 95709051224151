@use "sass:math";

$spinDuration: 1s;

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

button {
  font-weight: normal;
}

.button {
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  background-size: 1px 100px;
  border: none;
  border-radius: 3px;
  line-height: inherit;
  font-size: $regularSize;
  font-family: inherit;
  font-weight: 400;
  padding: 1.9rem 1.8rem 1.9rem;
  position: relative;
  box-sizing: border-box;

  &:hover {
    text-decoration: none;
  }

  &--primary {
    background-image: linear-gradient(
      $primaryGradientStart,
      $primaryGradientStart,
      $primaryGradientEnd
    );
    background-size: 200% 200%;
    border-color: $skyBlue;
    color: $white;
    transition: background-position $itemTransitionDuration;

    &:hover,
    &:focus {
      background-position: 0 100%;
    }
  }

  &--secondary {
    background-image: linear-gradient(
      $secondaryGradientStart,
      $secondaryGradientStart,
      $secondaryGradientEnd
    );
    background-size: 200% 200%;
    color: $white;
    transition: background-position $itemTransitionDuration;

    &:hover,
    &:focus {
      background-position: 0 100%;
    }
  }

  &--default {
    background-image: linear-gradient(
      $defaultGradientStart,
      $defaultGradientStart,
      $defaultGradientEnd
    );
    background-size: 200% 200%;
    border-color: $mainGray;
    color: $white;
    transition: background-position $itemTransitionDuration;

    &:hover,
    &:focus {
      background-position: 0 100%;
    }
  }

  &--discount {
    background-color: $discountColor;
    color: $white;

    &:hover,
    &:focus {
      background-image: $discountColor;
    }
  }

  &--filter {
    background-color: $white;
    border: 1px solid $skyBlue;
    border-radius: 50px;
    color: $skyBlue;

    &:hover,
    &:focus {
      background: $skyBlue;
      color: $white;
    }
  }

  &--disabled {
    background-color: $mainGray;
  }

  .inverted & {
    border-color: $shade03;
  }

  &--login {
    background-color: $skyBlue;
    border-color: $skyBlue;
    color: $white;
  }
}

.button--ghost {
  color: $shade04;
  border-color: $shade03;

  &:hover,
  &:focus {
    color: $fontColor;
  }
}
.button--mini {
  padding: 0.4rem 0.5rem 0.3rem;
}
.button--small {
  padding: 0.7rem 0.9rem 0.6rem;
}
.button--big {
  padding-left: $boxSizeMargin;
  padding-right: $boxSizeMargin;
}

.button--link,
.button--link-reverse {
  background: transparent;
  border: none;
  font-size: inherit;
  font-weight: inherit;
  text-transform: inherit;
  padding: 0.5rem;
  margin: -0.5rem;
  color: currentColor;
  text-transform: inherit;

  &:hover,
  &:focus {
    color: currentColor;
    background: transparent;
    text-decoration: underline;
  }
  &:focus-visible {
    outline: auto;
  }
  &.button--disabled {
    background: transparent;
  }
}

.button--warning {
  border-color: $warning;
  color: $warning;
}

.button--icon {
  display: block;
  margin: -0.5rem;
  padding: 0.5rem;
  border: none;
  color: inherit;
  background: transparent;
  border-color: $shade03;

  > * {
    display: block;
  }

  &:hover,
  &:focus {
    color: $brandPrimary;
    background: transparent;
  }
  &:focus {
    outline: 0;
  }
}

.button--icon-block,
.button--icon-block-primary {
  padding: 0.5rem;
  > * {
    display: block;
  }

  &:focus {
    outline: 0;
  }
  &.button--big {
    padding: 0.8rem;
  }
}
.button--icon-block {
  border-color: $shade03;
}
.button--icon-block-primary {
  align-items: center;
  background-color: $skyBlue;
  border-color: $skyBlue;
  color: $white;
  display: flex;
  height: 3.4rem;
  margin-left: $boxSizeMargin;
  padding: math.div($boxSizeMargin, 1.3);

  &:hover,
  &:focus {
    background-color: $skyBlue;
    border-color: $skyBlue;
    color: $white;
  }
}
.button--with-icon {
  padding: 0.9rem 1.6rem;

  .icon {
    margin-right: 1rem;
  }
}

.button--disabled {
  cursor: not-allowed;

  &,
  &:hover,
  &:focus {
    border-color: $shade03;
    background: $white;
    color: $shade03;
    &.button--primary {
      border-color: $shade03;
      background: $shade03;
      color: white;
    }
  }
}

.button--pending {
  color: inherit;
  cursor: pointer;

  &.button--search {
    color: $skyBlue;
  }

  &.button--primary,
  &.button--default {
    &,
    &:hover,
    &:focus {
      color: transparent;

      .labelled-icon__icon {
        display: none;
      }
    }
  }

  &.button--login {
    color: $skyBlue;

    &:hover {
      color: $skyBlue;
    }
  }

  &.button--warning {
    color: transparent;
    border: 1px solid $warning;
  }
  &.button--link {
    border: none;
  }
  &.button--icon {
    color: transparent;
    border: none;
    background: transparent;

    > * {
      color: transparent;
    }
  }
  &.button--icon-block-primary {
    color: transparent;
  }

  &:hover,
  &:focus {
    color: inherit;
  }

  &:after {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    content: "";
    display: block;
    margin: auto;
    border-radius: 50%;
    width: 1.2em;
    height: 1.2em;
    border: 1px solid $shade04;
    border-top-color: $shade05;
    animation: spin $spinDuration infinite linear;
  }
}
