.autocomplete {
  position: absolute;
  top: 100%;
  width: 100vw;
  z-index: 2;
  margin-top: 0.9rem;
  background: white;
  border: 1px solid $shade07;
  box-shadow: 0px 1rem 1rem #00000029;
}

@media screen and (min-width: $laptop) {
  .autocomplete {
    width: 100%;
    margin-top: 0.1rem;
  }
}

@import "~./AutocompleteResults/AutocompleteResults";
@import "~./AutocompleteItem/AutocompleteItem";
