@use "sass:math";

.multistep {
  position: relative;
  padding-bottom: 2rem;

  &__recap {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: $backgroundColor;
    padding-top: math.div($boxSizeMargin, 2);
    border-top: 1px solid $shade03;
    max-height: 84vh;
    max-height: calc(100vh - 7rem);
    overflow-y: auto;
  }
  &__current-step {
    padding-bottom: 7rem;
  }

  &__recap-items {
    min-height: calc(100vh - 12rem);
    &--closed {
      display: none;
    }
  }

  @media screen and (min-width: $menuBreakpoint) {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    &__current-step {
      flex: 2;
      padding-right: $boxSizeMargin * 2;
    }

    &__recap {
      position: initial;
      top: 0;
      flex: 1;
      min-width: 30em;
      padding-top: 0;
      padding-left: $boxSizeMargin * 2;
      background: transparent;
      border-top: none;
      max-height: 100%;
      overflow-y: visible;
    }
    &__recap-items {
      &--closed {
        display: block;
      }
    }
    &__progress {
      padding-top: 0.5rem;
      padding-bottom: 0.7rem;
      padding-left: $boxSizeMargin * 1.5;
      padding-right: $boxSizeMargin * 1.5;
    }
  }
}

@import "~./MultiStepRecap";
