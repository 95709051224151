@use "sass:math";

input,
select,
textarea {
  border: 1px solid $mainGray;
  background-color: white;
  border-radius: 5px;
  outline: none;
  font-size: $regularSize;
  font-family: $fontFamily;
  width: 100%;
  display: block;

  &[disabled] {
    color: $inputBorderColor;
  }

  &:focus {
    border-color: $shade05;
  }

  .inverted & {
    border-color: $backgroundColor;
  }
}

input {
  color: $brandPrimary;
  padding: 0 2.5rem;

  &::placeholder {
    color: $secondaryGray;
  }
}

.input--short {
  width: 7ch;
}

.input--invalid,
.textarea--invalid {
  border-color: $error;
  &:focus {
    border-color: $shade05;
  }
  .inverted & {
    border-color: $error;
    &:focus {
      border-color: $shade05;
    }
  }
}

.input--valid,
.textarea--valid {
  border-color: $shade05;
  .inverted & {
    border-color: $shade05;
  }
}

input,
.input-height,
select {
  height: 5.4rem;
}

textarea {
  padding: 1rem 2rem;
  min-width: 100%;
  max-width: 100%;
}

input[type="checkbox"],
input[type="radio"] {
  height: auto;
  width: auto;
  max-width: none;
  margin-right: $boxSizeMargin;
  margin-top: auto;
  margin-bottom: auto;
}

.input-wrapper--invisible {
  input,
  select,
  textarea {
    border: 0;
    padding: 0;
    border-radius: 0;
    max-width: 100%;
    &:hover {
      border-bottom: 1px solid $shade03;
    }
    &:focus {
      border-bottom: 1px solid $shade05;
    }
  }
}

.input-wrapper__error {
  color: $error;
  margin-top: math.div($boxSizeMargin, 2);
  padding-left: 2rem;
  padding-right: 2rem;

  .inverted & {
    color: $invertedWarning;
  }

  &--start {
    padding-left: 0;
    padding-right: 0;
  }
}

.input-wrapper__help {
  font-size: $tinySize;
  margin-top: math.div($boxSizeMargin, 2);
}

.input-wrapper {
  &__suggestions {
    .autocomplete-results__option {
      border-radius: 5px;
      cursor: pointer;
    }

    &-wrapper {
      position: relative;
      height: 0;
    }

    position: absolute;
    width: 100%;
    z-index: 2;
    background: $white;
    border: 1px solid $shade05;
    border-top: none;
    border-radius: 5px;
    box-sizing: border-box;
    padding: $boxSizeMargin;
  }
}
