.tabs {
  &__head {
    position: relative;
  }

  &__controls {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1px solid $shade03;
  }

  &__control {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 1rem;
    background-color: #fff;
    color: $brandPrimary;
    text-decoration: none;
    border-bottom: 1px solid $shade03;
    transition: 0.2s background-color ease-in-out,
      border-bottom-color 0.2s ease-in-out;
    cursor: pointer;

    &:nth-child(3),
    &:nth-child(4) {
      justify-content: center;
    }

    &:focus,
    &:hover {
      text-decoration: none;
    }

    &:hover {
      border-bottom-color: $shade03;
    }

    &.active {
      background-color: #fff;
    }

    &--is-not-tab {
      background-color: $white;
      border-bottom: none;
      border-left: none;

      &:last-child {
        border-right: none;
        border-left: none;
        border-bottom: 1px solid $shade07;
        text-decoration: underline;
        text-transform: lowercase;
      }
    }
  }

  &__content {
    display: none;

    &--active {
      display: block;
    }
  }
}

@media screen and (min-width: $horizontalTablet) {
  .tabs {
    &__controls {
      flex-direction: row;
      background: transparent;
    }
    &__content {
      .product-description {
        padding: 6rem;
      }
    }
    &__content--active {
      background-color: $pristineWhite;
    }
    &__control {
      padding: 2.6rem 1rem;
      justify-content: center;
      border-bottom: 1px solid $white;
      background-color: $shade07;
      text-align: center;

      &.active {
        background-color: $pristineWhite;
        border: 1px solid $pristineWhite;
      }

      &--is-not-tab {
        background-color: $shade02;
        border-bottom: 1px solid $shade07;
        border-left: 1px solid $shade07;
        justify-content: center;

        &:last-child {
          border-right: 1px solid $shade07;
        }
      }
    }
  }
}
