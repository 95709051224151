.accounting-line-table-filters {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  gap: 2rem;
}

@media screen and (min-width: $tablet) {
  .accounting-line-table-filters {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    .date-range-picker {
      padding-bottom: 2rem;
    }
  }
}
