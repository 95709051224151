.after-sales-service {
}

@media screen and (min-width: $desktop) {
  .after-sales-service {
    &__container {
      overflow-x: hidden;
      max-width: 100%;
    }
  }
}
