$addToCartButtonHeight: 5.5rem;

.add-to-cart-button {
  .button {
    height: $addToCartButtonHeight;
    width: 100%;
    padding: 0 2.4rem;

    &--disabled {
      color: $white;

      &:hover,
      &:focus {
        color: $white;
      }
    }

    .labelled-icon {
      &__icon {
        margin-right: 0.7rem;
        margin-bottom: 0;

        .icon-cart {
          img {
            width: 3rem;
            height: 2.7rem;
          }
        }
      }

      .body {
        margin-bottom: 0;
        line-height: $addToCartButtonHeight;
        font-weight: $semiBold;
        white-space: nowrap;
      }
    }
  }
}
