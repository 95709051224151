@use "sass:math";

.product-item {
  position: relative;
  overflow: hidden;
  border: 0.5px solid $mainGray;
  background-color: white;
  height: 100%;

  .icon-white-percentage {
    background-color: $color03;
    padding: 1.75rem;

    .icon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .icon-white-new-tag {
    background-color: $brandSecondary;

    padding: 1.5rem;
    .icon {
      width: 2rem;
      height: 2rem;
    }
  }

  .icon-white-percentage,
  .icon-white-new-tag {
    border-radius: 50%;
  }

  &__image-container {
    background-color: white;
    overflow: hidden;
  }

  &__image {
    display: flex;
    justify-content: center;
    object-fit: cover;
    width: 100%;
    position: relative;
  }

  &__details {
    border-top: 1px solid $mainGray;
    padding: math.div($boxSizeMargin, 2);
  }

  &__actions {
    display: none;
  }

  &__overview {
    margin-bottom: $boxSizeMargin;
  }
}

@media screen and (min-width: $laptop) {
  .product-item {
    &__actions {
      display: flex;
      gap: 1.2rem;
      visibility: hidden;
      opacity: 0;
    }

    &__action {
      flex-grow: 1;
      &:hover .link {
        text-decoration: none;
      }
      .button {
        width: 100%;
      }
    }

    &:hover .product-item__actions {
      visibility: visible;
      opacity: 1;
    }

    &__details {
      padding: $boxSizeMargin;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    &__label {
      position: absolute;
      top: $boxSizeMargin;
      left: $boxSizeMargin * 1.5;
    }

    .icon-white-percentage {
      padding: 2rem;
      .icon {
        width: 1.7rem;
        height: 1.7rem;
      }
    }
    .icon-white-new-tag {
      padding: 1.6rem;
      .icon {
        width: 2.5rem;
        height: 2.5rem;
      }
    }
  }
}

@media print {
  .product-item {
    &__actions {
      display: none;
    }
  }
}
