.layer-products {
  display: flex;
  flex-wrap: wrap;

  &__element {
    flex-basis: 50%;
    max-width: 50%;
  }
}

@media screen and (min-width: $laptop) {
  .layer-products {
    &__element {
      flex-basis: 33%;
      max-width: 33%;
    }
  }
}

@media screen and (min-width: $desktop + 200) {
  .layer-products {
    &__element {
      flex-basis: 25%;
      max-width: 25%;
    }
  }
}
