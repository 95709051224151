.category-result {
  font-weight: normal;
}

.searchbar-category-results {
  background-color: $shade01;

  .autocomplete-item__name {
    .category-result {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 3.7rem;
      line-height: 1.9rem;
    }
  }
}
