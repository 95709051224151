.wishlist-product-category {
  margin: 20px;
}

.wishlist-product-grid {
  display: flex;
  flex-wrap: wrap;

  &--no-items {
    padding: 0 1.4rem;
  }

  &__element {
    flex-basis: 50%;
    max-width: 50%;
  }
}

@media screen and (min-width: $laptop) {
  .wishlist-product-grid {
    &--no-items {
      padding: 0 3.3rem;
    }

    &__element {
      flex-basis: 33%;
      max-width: 33%;
    }
  }
}

@media screen and (min-width: $desktop) {
  .wishlist-product-grid {
    &__element {
      flex-basis: 25%;
      max-width: 25%;
    }
  }
}
