.requisition-list-details {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6rem;
  }

  &__title {
    display: flex;
    align-items: center;

    > * {
      font-size: 2.4rem;
      font-weight: $bold;
    }

    span {
      white-space: nowrap;
      margin-right: 1.5rem;
    }
  }

  &__errors {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2rem;

    color: $error;

    b {
      font-weight: $semiBold;
    }
  }

  &__last-saved {
    font-size: 2.4rem;
    font-weight: $bold;
    margin-bottom: 2rem;
  }

  .totals {
    padding: 4rem 1rem;
    border-top: 1px solid $brandPrimary;
    border-bottom: 1px solid $brandPrimary;
  }
}

@media print {
  .requisition-list-details {
    &__header {
      border-bottom: 0.5cm;
    }

    &__title {
      > * {
        font-size: 14pt;
      }

      input {
        height: 1cm;
      }
    }

    .table-row {
      .table-header-cell:first-child {
        display: none;
      }
    }
  }
}
