.custom-modal {
  width: 100%;
  max-width: 102.4rem;
  margin: 1rem;

  &__container {
    border: 1px solid $shade07;
    background-color: white;
  }

  &__header {
    padding: 2.4rem 1.6rem;
  }

  &__title {
    text-align: center;
    font-size: 1.9rem;
    line-height: 2.5rem;
    margin-right: 3rem;
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1.5rem;

    svg {
      width: 2.4rem;
      height: 2.4rem;
      path {
        stroke: $mainGray;
      }

      &:focus,
      &:hover {
        path {
          stroke: $brandPrimary;
        }
      }
    }
  }

  &__content {
    padding: 0 1.6rem 2.4rem;
  }
}

@media screen and (min-width: $tablet) {
  .custom-modal {
    &__header {
      padding: 4.5rem 3.5rem 3.5rem;
    }
    &__title {
      font-size: 2.8rem;
    }
    &__content {
      padding: 0 3.5rem 3.5rem;
    }
  }
}
