@use "sass:math";

.autocomplete-results {
  display: block;
  padding: 0;
  margin: 0;
  list-style: none;

  &__option {
    position: relative;
    display: block;
  }
}
