.layer-page {
  background-color: $pristineWhite;

  &__title {
    display: flex;
    align-items: center;
    padding: 0 1.4rem;
    height: 6rem;
    border-bottom: 1px solid $shade07;

    h1 {
      font-size: 2.8rem;
      line-height: 2.5rem;
      font-weight: $semiBold;
    }
  }

  &__sub-categories {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    padding: 2rem 3.3rem 2rem;
    border-bottom: 1px solid $shade07;

    .sub-category-item {
      cursor: pointer;
      background-color: $brandPrimary;
      color: white;
      border-radius: 1rem;

      .link {
        display: block;
        padding: 1.5rem;
      }

      &:hover,
      &:focus {
        background-color: $brandSecondary;

        .link {
          text-decoration: none;
        }
      }
    }
  }

  .filters {
    &__container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 0.6rem;
      padding: 2.3rem 1.3rem;

      .custom-checkbox {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 49%;
        width: 18rem;
        height: 4.9rem;
        border: 1px solid $skyBlue;
        border-radius: 2.5rem;
        color: $skyBlue;
        transition: ease-in-out 0.3s color, ease-in-out 0.3s background-color;

        .input-wrapper {
          width: 100%;

          .checkbox {
            padding: 1.8rem 0;

            input {
              margin-right: 0;
              cursor: pointer;
            }
            label {
              font-size: 1.6rem;
              padding: 0;
              width: 100%;
              text-align: center;
              user-select: none;

              &::before {
                display: none;
              }
            }
          }
        }

        &--is-checked,
        &:hover {
          background-color: $skyBlue;
          color: $pristineWhite;
        }
      }
    }
  }
}

@media screen and (min-width: $laptop) {
  .layer-page {
    display: flex;
    flex-direction: column;

    &__title {
      padding: 0 3.3rem;

      h1 {
        font-size: 3rem;
      }
    }

    .filters {
      &__container {
        justify-content: flex-start;
        gap: 1.5rem;
        padding: 3rem 3.3rem;

        .custom-checkbox {
          width: 20rem;
          flex: initial;
        }
      }
    }
  }
}

@media screen and (min-width: $desktop) {
  .layer-page {
    display: flex;
    flex-direction: column;

    &__title {
      .h1 {
        margin-top: 0.5rem;
        margin-bottom: 1.2rem;
      }
    }
  }
}
