.not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

@media (min-width: $laptop) {
  .not-found {
    &__image-container {
      max-width: 50%;
    }
  }
}
