.layer-sorting {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__element {
    font-weight: $bold;
  }

  &--standalone {
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    flex-wrap: wrap;
  }
}
