.custom-menu-navigation-container {
  display: flex;

  .custom-menu-navigation {
    position: absolute;
    z-index: 100;
    left: 0;
    right: 0;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
    min-height: 100vh;
    background-color: #f4f7f6;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      top: 0;
      left: 0;
      right: 0;
      z-index: -1;
      border-right: 1px solid $shade07;
    }

    &--open {
      transform: translateX(0%);
    }

    .new-category-item {
      border-left: 6px solid $color02;
      background-color: $pristineWhite;

      .category-item {
        padding: 0 1.8rem 0 1.4rem;
      }

      .icon-primary-new-tag {
        img {
          width: 2.5rem;
          height: 2.5rem;
        }
      }
    }

    .discount-category-item,
    .everparts-item {
      border-left: 6px solid $color03;
      background-color: $pristineWhite;

      .category-item {
        padding: 0 2.2rem 0 1.4rem;
      }

      img {
        width: 1.7rem;
        height: 1.7rem;
      }
    }

    .everparts-item {
      border-left: 6px solid $color02;
    }

    .discount-category-item,
    .new-category-item,
    .everparts-item {
      .link {
        &:hover,
        &:focus {
          text-decoration: none;
        }
      }
    }

    .go-to {
      display: flex;
      align-items: center;
      gap: 1rem;
      padding: 0 1.5rem;
      height: 6rem;
      border-bottom: 1px solid $shade07;
      border-right: 1px solid $shade07;
      background-color: $pristineWhite;
      font-size: 1.4rem;

      .icon-chevron-left {
        svg {
          width: 3.2rem;
          height: 3.2rem;
        }
      }
    }

    .category-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 1.3rem 0 2rem;
      height: 6rem;
      border-bottom: 1px solid $shade07;
      border-right: 1px solid $shade07;
      background-color: $white;
      user-select: none;

      &__label {
        font-size: 1.4rem;
        line-height: 1.7rem;
        letter-spacing: 0;
        user-select: none;
      }

      &--has-children {
        color: $skyBlue;
        font-weight: $bold;
      }

      .icon-chevron-right {
        svg {
          width: 3.2rem;
          height: 3.2rem;
        }
      }

      .icon {
        color: $mainGray;
      }

      .link {
        text-decoration: none;
      }
    }

    .everparts-item {
      .category-item {
        .icon {
          color: $brandPrimary;
        }
      }
    }
  }

  .desktop-sub-categories-menu {
    display: none;
  }
}

@media screen and (min-width: $laptop) {
  .custom-menu-navigation-container {
    .custom-menu-navigation {
      position: initial;
      width: $navigationMenuWidth;
      transform: translateX(0%);
      top: calc(-5rem + 0px);
      .category-item {
        cursor: pointer;

        &--selected {
          background-color: $brandPrimary;
          color: $pristineWhite;
        }

        .icon-chevron-right {
          display: none;
        }
      }
    }

    .desktop-sub-categories-menu {
      display: block;
      z-index: 99;
      position: absolute;
      width: calc(100vw - $navigationMenuWidth);
      max-width: 153rem;
      min-height: 100%;
      left: $navigationMenuWidth;
      background-color: $pristineWhite;
      box-shadow: 0px 25px 30px #00000029;
      border: 1px solid $shade07;
      border-left: none;
      border-top: none;
      transform: translateX(-100%);
      transition: transform 300ms ease;

      &--open {
        transform: translateX(0%);
      }

      &__top-bar {
        display: flex;
        justify-content: space-between;
        padding: 4.2rem 4.2rem 4.2rem 3rem;
        border-bottom: 1px solid $shade07;
      }

      &__main-category-name {
        font-size: 3rem;
        color: $brandPrimary;
      }

      &__close {
        color: $mainGray;

        .icon-cross {
          svg {
            width: 2.4rem;
            height: 2.4rem;

            path {
              stroke: $mainGray;
            }
          }
        }
      }

      &__sub-categories {
        .sub-category {
          display: grid;
          grid-template-columns: 0.9fr 3fr;
          padding: 3.4rem 3rem;
          border-bottom: 1px solid $shade07;

          &__name {
            font-size: $largeSize;
            color: $skyBlue;
            font-weight: $bold;
            margin-right: 12.8rem;
          }

          &__categories {
            display: grid;
            grid-auto-flow: column;
            grid-template-columns: repeat(4, 1fr);
            row-gap: 1rem;
            column-gap: 3rem;
            font-size: $regularSize;

            .link {
              &:hover,
              &:focus {
                color: $skyBlue;
                text-shadow: 1px 0px 0px $skyBlue;
              }
            }
          }

          &__item {
            min-height: 3.2rem;
          }
        }
      }
    }
  }
}
