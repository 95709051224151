.searchbar-results {
  width: 100%;

  &--empty {
    text-align: center;
    padding: 3.6rem;

    p {
      margin: 0;
      line-height: 1;
      color: $brandPrimary;
    }
  }

  &__results {
    display: flex;
    flex-direction: column;
    max-height: 40rem;
    overflow-y: auto;
  }

  &__element {
    width: 100%;
  }

  &__see-all-results {
    text-align: center;
    padding: 1.5rem;
  }
}

@media screen and (min-width: $tablet) {
  .searchbar-results {
    width: auto;

    &__results {
      max-height: 60rem;
    }
  }
}
