@use "sass:math";

.search-box {
  align-items: center;
  background-color: $brandPrimary;
  border: none;
  display: flex;
  width: 100%;
  height: 7.5rem;
  position: relative;

  &__form {
    width: 100%;

    .one-line-form {
      display: flex;
      align-items: center;
      height: 7.5rem;
      margin-right: 0.9rem;

      &__input {
        position: relative;
        min-width: 0;

        input {
          background-color: $brandPrimary;
          border: none;
          color: $white;
          padding: 0 1.5rem;
          height: 5.6rem;

          &:focus {
            border: none;
          }

          &::placeholder {
            color: $white;
          }
        }
      }

      &__button {
        .button {
          width: 5.6rem;
          height: 5.6rem;
          margin-left: 0;
          padding: 0;

          .labelled-icon {
            display: flex;
            justify-content: center;

            &__icon {
              margin-right: 0;
              svg {
                width: 3rem;
                height: 3rem;
              }
            }

            .body {
              display: none;
            }
          }
        }
      }
    }
  }
}

@media (min-width: $desktop) {
  .search-box {
    margin-left: 2.2rem;
    &__form {
      .one-line-form {
        &__input {
          input {
            border: 1px solid $skyBlue;
            border-right: none;
            border-radius: 0;

            &:hover,
            &:focus {
              border: 1px solid $skyBlue;
            }
          }
        }

        &__button {
          .button {
            width: auto;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;

            .labelled-icon {
              &__content {
                padding: 0 4.3rem 0 3.3rem;
              }

              .body {
                display: block;
                margin-left: 0.6rem;
              }
            }
          }
        }
      }
    }
  }
}

@media print {
  .search-box {
    display: none;
    background-color: $white;
    height: 0;

    div,
    .form {
      display: none;
    }
  }
}

@import "~./SearchBarResults/SearchBarResults";
@import "~./CategoriesResults/CategoriesResults";
