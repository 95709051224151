@page {
  margin: 1.2cm;
}

@media print {
  /**
  * Reset properties
  */

  * {
    all: unset;
    display: revert;
  }
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  img {
    max-width: 100%;
  }

  /**
  * Global sizes
  */

  body {
    font-size: 12pt;
    margin: 0;
    padding: 0;
    -webkit-print-color-adjust: exact; // to display backgrounds
    print-color-adjust: exact;
  }

  h1 {
    font-size: 18pt;
  }
  h2 {
    font-size: 17pt;
  }
  h3 {
    font-size: 16pt;
  }
  h4,
  h5,
  h6 {
    font-size: 13pt;
  }

  /**
  * Break lines
  */

  ul,
  ol,
  dl,
  a,
  table,
  pre,
  blockquote {
    page-break-inside: avoid;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  img,
  figure,
  caption {
    page-break-inside: avoid;
    page-break-after: avoid;
  }

  /**
  * Utils
  */

  .no-print {
    display: none;
  }
}
