.checkout-address {
  padding: 2rem;

  .existing-address {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;

    &__billing-address {
      .address {
        @extend .radio-grid__element;
        border-color: $brandSecondary;

        .body {
          margin: 0;
        }
      }
    }

    .form-title {
      margin-bottom: 2rem;
    }
  }

  .radio-grid__element {
    height: fit-content;
  }
}
