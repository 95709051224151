.after-sales-service-table {
  &__opening-date {
    min-width: 11rem;
  }
  &__designation {
    max-width: 40rem;
  }
  &__state {
    min-width: 13rem;
  }
  &__details {
    .button--icon {
      margin: auto;
      padding: 0;
    }
  }
}
