@use "sass:math";

.icon {
  object-fit: contain;
  width: 2.4rem;
  height: 2.4rem;
  vertical-align: middle;

  &--mini {
    width: 1.3rem;
    height: 1.3rem;
  }
  &--small {
    width: 1.9rem;
    height: 1.9rem;
  }
  &--medium {
    width: 2.6rem;
    height: 2.6rem;
  }
  &--big {
    width: 3.6rem;
    height: 3.6rem;
  }
}

.icon-wrapper {
  > * {
    display: block;
  }

  &--default-block {
    display: block;
  }
  &--default {
    display: inline-block;
    vertical-align: middle;
  }
  &--block {
    display: inline-block;
    position: relative;
    padding: 0.6rem;

    &::before {
      content: "";
      position: absolute;
      background: currentColor;
      border-radius: 0.6rem;
      opacity: 0.1;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    &.icon-wrapper--big {
      padding: 1.3rem;
      &::before {
        border-radius: 0.9rem;
      }
    }
  }

  &--round,
  &--round-border {
    display: inline-block;
    position: relative;
    padding: math.div($boxSizeMargin, 2);
    border-radius: $boxSizeMargin * 2;
  }
  &--round {
    background: currentColor;
    > * {
      color: $white;
    }
  }
  &--round-border {
    border: 1px solid currentColor;
  }
}

/* Icon specific style override */
.icon-cross {
  svg {
    path {
      stroke-width: 3px;
    }
  }
}
