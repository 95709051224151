@use "sass:math";

.product-overview {
  &__name {
    font-size: $largeSize;
  }

  &__ref {
    font-size: $veryLargeSize;
    margin-top: math.div($boxSizeMargin, 4);
    text-decoration: none;
    display: inline-block;
  }

  &__sold-out {
    color: $shade04;
    font-size: $regularSize;
    min-height: 2.5rem;
  }

  &__rating {
    margin-top: math.div($boxSizeMargin, 4);
  }

  &__tag {
    position: absolute;
    top: math.div($boxSizeMargin, 2);
    left: math.div($boxSizeMargin, 2);
    // handle possibly many labels/tags
    display: flex;
    gap: math.div($boxSizeMargin, 3);
  }
}

@media print {
  .product-overview {
    &__tag {
      .icon-white-percentage {
        padding: 0.2cm;
      }
    }

    &__name {
      font-size: 12pt;
    }

    &__row {
      gap: 0.1cm;
      flex-direction: column;
    }

    &__sku {
      font-size: 11pt;
    }
  }
}
