@use "sass:math";

.stack {
  display: flex;
  flex-direction: column;
  margin-top: $boxSizeMargin;

  &__element {
    &:empty {
      display: none;
    }

    .stack > &,
    .stack--size-1 > &,
    .stack--size-mobile-1 > & {
      padding-top: math.div($boxSizeMargin, 4);
      padding-bottom: math.div($boxSizeMargin, 4);
    }
    .stack--size-2 > &,
    .stack--size-mobile-2 > & {
      padding-top: math.div($boxSizeMargin, 2);
      padding-bottom: math.div($boxSizeMargin, 2);
    }
    .stack--size-4 > &,
    .stack--size-mobile-4 > & {
      padding-top: $boxSizeMargin * 1;
      padding-bottom: $boxSizeMargin * 1;
    }
    .stack--size-8 > &,
    .stack--size-mobile-8 > & {
      padding-top: $boxSizeMargin * 2;
      padding-bottom: $boxSizeMargin * 2;
    }

    @media screen and (min-width: $menuBreakpoint) {
      .stack > &,
      .stack--size-1 > &,
      .stack--size-desktop-1 > & {
        padding-top: math.div($boxSizeMargin, 2);
        padding-bottom: math.div($boxSizeMargin, 2);
      }
      .stack--size-2 > &,
      .stack--size-desktop-2 > & {
        padding-top: $boxSizeMargin * 1;
        padding-bottom: $boxSizeMargin * 1;
      }
      .stack--size-4 > &,
      .stack--size-desktop-4 > & {
        padding-top: $boxSizeMargin * 2;
      }
      .stack--size-8 > &,
      .stack--size-desktop-8 > & {
        padding-top: $boxSizeMargin * 3;
        padding-bottom: $boxSizeMargin * 3;
      }
    }

    // Needs to be applied after media query to match all contexts
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }

    a,
    .link {
      display: -webkit-flex;
    }
  }
}
