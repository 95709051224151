.items-slider {
  .container {
    padding: 0;
  }
  overflow: hidden;
  &__container {
    position: relative;
  }
  &__slider {
    position: relative;
  }
  &__item {
    display: grid;
  }

  &__element {
    padding: 0;
    display: flex;

    .product-item {
      width: 100%;
    }

    &--discount-tag {
      position: relative;
      padding: $boxSizeMargin;

      border: none;
      color: white;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;

      transition: box-shadow 200ms, background-position 200ms;

      background-image: linear-gradient(
        180deg,
        $discountColorGradient-1 0%,
        $discountColorGradient-1 25%,
        $discountColorGradient-2,
        $discountColorGradient-2,
        $discountColorGradient-1,
        $discountColor 100%
      );
      background-size: 200% 200%;
      background-position: 0 0;
      background-repeat: no-repeat;

      &__title {
        font-size: $veryLargeSize;
        font-weight: $semiBold;
        margin: 0;
        position: relative;
        z-index: 2;
        pointer-events: none;
      }

      .link {
        display: none;
        font-weight: $superLight;
        text-decoration: underline;
      }

      &__image {
        position: absolute;
        bottom: $boxSizeMargin;
        right: $boxSizeMargin * 1.5;
        line-height: 0;
      }
    }
  }

  &__controls {
    display: none;
  }

  // By putting this after the media query, it takes precedence over the rules in the
  // media query. This is intended because there is no difference of the vertical
  // view in mobile or desktop.
  &--vertical {
    &,
    > *,
    .items-slider__container,
    .items-slider__item {
      height: 100%;
    }

    .items-slider__container {
      padding-right: 0;
    }

    .items-slider__slider {
      height: calc(100% - #{$boxSizeMargin * 4});
      margin-bottom: 0;
      overflow: hidden;
    }
    .items-slider__element {
      width: 100%;
      max-width: 100%;
      &:empty {
        flex: reset;
        padding: 100% 0 0 0;
      }
    }

    .items-slider__item {
      flex-direction: column;
      justify-content: flex-start;
    }

    .items-slider__controls {
      display: flex;
      justify-content: center;
      margin-top: 0;
      padding-top: $boxSizeMargin;
    }

    .items-slider__previous,
    .items-slider__next {
      position: static;
      transform: none;
    }
  }

  .link {
    flex: 1;
    text-decoration: none;
  }
}

.promotion-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding: 2.7rem 0 0 2rem;
  height: 100%;
  border: none;
  color: white;
  transition: background 400ms ease-out, border 400ms ease-out;

  background-image: linear-gradient(
    180deg,
    $discountColorGradient-1 0%,
    $discountColorGradient-1 25%,
    $discountColorGradient-2,
    $discountColorGradient-2,
    $discountColorGradient-1,
    $discountColor 100%
  );
  background-size: 200% 200%;
  background-position: 0 0;
  background-repeat: no-repeat;

  &__title {
    width: 75%;
    font-size: 1.9rem;
    line-height: 2.2rem;
    pointer-events: none;
  }

  &__link {
    visibility: hidden;
  }

  &__image {
    position: absolute;
    right: 1.1rem;
    bottom: 1.3rem;

    img {
      width: 5rem;
      height: 5rem;
    }
  }
}

@media screen and (min-width: $tablet) {
  .promotion-item {
    padding-bottom: 3rem;
    &__link {
      visibility: visible;
      text-decoration: underline;
    }
  }
}

@media screen and (min-width: $laptop) {
  .promotion-item {
    height: 100%;
    padding: 3.6rem 0 4.4rem 2.9rem;
    background-color: $discountColor;
    background-image: url(/assets/pourcentage-rouge.svg),
      linear-gradient(
        180deg,
        $discountColorGradient-1,
        $discountColorGradient-1 25%,
        $discountColorGradient-2,
        $discountColorGradient-2,
        $discountColorGradient-1,
        $discountColor
      );
    background-size: 18.7rem, 200% 200%;
    background-position: 55%, 0 0;
    background-repeat: no-repeat;

    &:before {
      pointer-events: none;
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border-radius: 2px;
      border: 4px solid $color03;
      opacity: 0;
    }

    &:hover {
      background-position: 55%, 0 100%;

      &:before {
        opacity: 1;
      }
    }

    &__title {
      width: 85%;
      font-weight: $semiBold;
      font-size: 2.8rem;
      line-height: 3.5rem;
      max-width: 32rem;
    }

    &__link {
      padding-left: 0.5rem;
    }

    &__image {
      right: 3.2rem;
      bottom: 2.4rem;

      img {
        width: 6rem;
        height: 6rem;
      }
    }
  }

  .items-slider {
    &__element {
      &--discount-tag {
        padding: 1.8rem;

        .link {
          display: block;
          font-size: $largeSize;
        }
      }
    }

    .link {
      flex: 0.33;
    }
  }
}

@media print {
  .promotion-item {
    &__title {
      font-size: 14pt;
    }
  }
}
