.wavesoft-order-table {
  .wavesoft-order-table-row {
    &__origin {
      white-space: nowrap;
    }

    .link {
      font-weight: $bold;

      &:focus,
      &:hover {
        color: $brandSecondary;
        text-decoration: none;
      }
    }
  }

  .wavesoft-order-status {
    font-weight: $semiBold;
  }
}
