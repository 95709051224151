.requisition-list-extension-attributes {
  &__main-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 3rem;
    padding: 4rem 1rem 0;

    .form-item {
      flex-basis: 50%;

      textarea {
        resize: none;
      }
    }
  }

  &__right-container {
    display: flex;
    gap: 4rem;

    .input-wrapper {
      .checkbox {
        label {
          font-weight: $bold;
        }
      }
    }
  }

  .form-item {
    &__label {
      font-weight: $bold;
      margin-bottom: 0.5rem;
    }
  }
}

@media screen and (min-width: $tablet) {
  .requisition-list-extension-attributes {
    &__main-container {
      flex-direction: row;
      justify-content: space-between;
    }
  }
}

@media print {
  .requisition-list-extension-attributes__right-container {
    label {
      display: flex;
      &::before {
        border: 1pt solid #209cad;
        border-radius: 3pt;
        width: 0.6cm;
        height: 0.6cm;
        margin-right: 0.25cm;
        background-color: $white;
        color: #209cad;
        text-align: center;
        content: "";
      }
    }

    .checkbox input[type="checkbox"]:checked + label::before {
      content: "✔";
    }
  }
}
