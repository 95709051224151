.requisiton-list-quick-add-to-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem 1rem;

  &__title {
    font-weight: $bold;
  }
}
