.requisition-list-items-table {
  div.requisition-list-items-table-row {
    &__handle {
      display: none;
    }
    &__move-actions {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      margin-right: 2rem;

      .button {
        border: 2px solid currentColor;

        .icon {
          width: 4rem;
          height: 4rem;
        }
      }
    }
  }

  tr.requisition-list-items-table-row,
  div.requisition-list-items-table-row,
  td.requisition-list-items-table-row {
    &--dragged {
      // any dragged style here, for the moment none
    }

    &--hovered {
      box-shadow: 0px 2px 1px $brandSecondary;
    }

    &__handle {
      cursor: move;
    }

    &__sku {
      .link {
        font-weight: $bold;

        &:focus,
        &:hover {
          color: $brandSecondary;
          text-decoration: none;
        }
      }
    }

    &__name-and-comment-container {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      text-align: left;

      .form-item__label {
        font-weight: $bold;
      }
    }

    &__quantity {
      max-width: 14rem;

      .spinner {
        /* Prevent cell to change size on loading */
        height: 5.6rem;
        width: 12.8rem;
      }

      .naked-error-message {
        margin-top: 1rem;
      }
    }
  }
}

@media screen and (min-width: $tablet) {
  .requisition-list-items-table {
    div.requisition-list-items-table-row {
      &__handle {
        display: block;
      }

      &__move-actions {
        display: none;
      }
    }
  }
}

@media print {
  .requisition-list-items-table-row {
    &__handle,
    &__move-actions {
      display: none;
    }

    &__quantity {
      .number-input {
        min-width: 1cm;

        &__button {
          display: none;
        }

        &__input > * {
          width: 1cm;
          font-size: 14pt;
        }
      }
    }

    &__name-and-comment-container,
    label,
    .table-cell {
      font-size: 13pt;
    }

    .table-cell {
      padding: 0.5cm 0.15cm;
    }

    .table-cell:first-child {
      display: none;
    }

    input {
      height: 1.5cm;
      padding: 0 0.1cm;
    }
  }
}
