.wavesoft-order-document-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .wavesoft-order-document {
    &__label {
      color: $brandPrimary;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      white-space: nowrap;

      &--has-content {
        cursor: pointer;
        &:focus,
        &:hover {
          color: $brandSecondary;
        }
      }

      .icon {
        width: 2rem;
        height: 2rem;
      }
    }
  }
}
