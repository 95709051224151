.address-book {
  &__container {
    margin-bottom: 2rem;
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
    margin-bottom: 2rem;
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  &__defaults {
    display: grid;
    grid-template-columns: initial;
    grid-template-rows: 1fr 1fr;
    margin-bottom: 2rem;
  }

  &__default-billing {
    margin-bottom: 4rem;

    .account-page-title {
      margin-bottom: 1rem;
    }

    .account-page-sub-title {
      margin-bottom: 1.6rem;
      color: $shade08;
      font-style: italic;
      font-size: 1.4rem;
    }
  }
}

@media screen and (min-width: $tablet), print {
  .address-book {
    &__container {
      margin-bottom: 4rem;
    }

    &__items {
      grid-template-columns: repeat(3, 1fr);
    }

    &__defaults {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: initial;
    }

    &__default-billing {
      margin-bottom: 0;
    }
  }
}

.create-address-modal {
  width: fit-content;
}

@media print {
  .address-book {
    .form-actions,
    .editable-address__button,
    .button {
      display: none;
    }
  }
}
