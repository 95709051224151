.order-table {
  &__order-id {
    .link {
      font-weight: $bold;

      &:focus,
      &:hover {
        color: $brandSecondary;
        text-decoration: none;
      }
    }
  }

  &__transmitted {
    font-weight: $semiBold;
    color: $success;
  }
}

@media print {
  .details-link {
    display: none;
  }
}
