@use "sass:math";

.order-details-layout {
  display: flex;
  flex-direction: column;

  &__back {
    margin: 2rem 0;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }
  &__title {
    font-weight: $bold;
    font-size: $mediumSize;

    > * {
      margin: 0;
    }

    .transmitted-status {
      color: $green;
    }
  }
  &__cart-name {
    margin: 0.5rem 0 3rem;
  }

  &__top-container {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &__addresses {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2rem;
    margin-left: 3rem;
  }

  &__delivery-methods {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
  }
}

@media screen and (min-width: $laptop) {
  .order-details-layout {
    &__top-container {
      gap: 10rem;
    }

    &__addresses {
      flex-direction: row;
      gap: 10rem;
    }

    &__title {
      font-size: $largeSize;
    }
  }
}
