.recap-table {
  margin: 0;

  &__group {
    margin: $boxSizeMargin 0;

    &__title {
      margin-bottom: $boxSizeMargin;
      display: flex;
      justify-content: space-between;
    }

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__line {
    display: flex;
    justify-content: flex-end;
    line-height: 2.9rem;
    font-weight: $bold;
    font-size: $mediumSize;

    &--nice {
      color: $success;
    }
    &--final {
      margin-top: 0.5rem;
      font-size: $mediumSize;
      font-weight: bold;
    }
    &--standalone {
      > * {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    .price {
      min-width: 14rem;
      font-weight: $regular;
    }
  }
}
