a,
.link {
  color: currentColor;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
  &:focus-visible {
    outline: auto;
    outline-offset: 0.5rem;
  }

  p & {
    text-decoration: underline;
  }
}

.link--text {
  color: currentColor;
}

.link--small {
  font-size: $extraSmallSize;
  color: $black;
}

.link--block {
  display: -webkit-flex;
  text-decoration: none;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
