.stock {
  display: inline-block;
  width: fit-content;
  white-space: nowrap;
  color: $skyBlue;
  border: 1px solid currentColor;
  font-weight: $semiBold;
  font-size: $smallSize;
  padding: 1rem 1.8rem;

  &--not-managed {
    color: $yellow;
  }
}
