.wishlist-page {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 20px;

    &__total {
      height: 7.6rem;
      display: flex;
      align-items: center;
      background-color: $shade01;
      padding: 1rem;
      font-weight: $bold;
      color: $shade08;
      border-bottom: 1px solid $shade07;
    }
  }
}
