.editable-address {
  &__address {
    > :first-child {
      margin-top: 0;
    }
    > :last-child {
      margin-bottom: 0;
    }

    p {
      line-height: 2.5rem;
    }
  }
  &__button {
    margin-top: 1.9rem;
    margin-bottom: -0.5rem;
    position: relative;
    z-index: 1;
    font-weight: $bold;
  }
}

.editable-address-modal {
  width: fit-content;
  &__title {
    font-weight: $semiBold;
  }
}
