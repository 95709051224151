@use "sass:math";

.product-overview {
  &__name {
    font-size: $smallSize;
    font-weight: bold;
    margin-bottom: 1rem;
    &:hover {
      .link {
        text-decoration: none;
      }
    }
  }

  &__sku {
    display: flex;
    align-items: center;
    font-size: $smallSize;
    word-break: break-all;
    margin: 0;
  }

  &__row {
    display: flex;
    gap: $boxSizeMargin;
    justify-content: space-between;
    align-items: center;
    min-height: 3.6rem;
  }
}

.product-overview-skeleton {
  &__name {
    font-size: $largeSize;
  }
}

@media screen and (min-width: $mediumTabletBreakpoint) {
  .product-overview {
    &__name {
      font-size: $mediumSize;
    }
  }
}
